import _ from "lodash";
import React, { useEffect } from "react";
import { languages } from "config/language/language";
import { useSetting } from "App/System/settings/SettingsProvider";
import { useUser } from "worklete/hooks";
import { i18n } from "worklete/translations/i18n-config";
import { LocalizationProviders } from "./LocalizationProviders";

// manages and exposes the active language code.
// `language_code` setting may override code set
// in user's data.

const CODES = _.map(languages, "code");

const isSupportedCode = (code) => _.includes(CODES, code);

const LocalizationManager = ({ children }) => {
  const setting_code = useSetting("language_code");
  const { language_code: user_code } = useUser();

  const code = isSupportedCode(setting_code)
    ? setting_code
    : isSupportedCode(user_code)
    ? user_code
    : "en";

  useEffect(() => {
    i18n.changeLanguage(code);
  }, [code]);

  return (
    <LocalizationProviders language_code={code}>
      {children}
    </LocalizationProviders>
  );
};

export default LocalizationManager;
