import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/styles";
import { useTranslation } from "worklete/hooks";

import { Text } from "worklete/components";

import "../IndividualSignUpSteps.i18n";

const styles = (theme) => ({
  value: {
    display: "inline",
    color: theme.palette.text.light,
    fontSize: "16px",
    fontWeight: 500,
  },
  editLink: {
    display: "inline",
  },
  showPassword: {
    color: theme.palette.text.dark,
    fontSize: "14px",
    marginBottom: "3px",
  },
});

const convertPassword = (pw = "") => pw.split("").map((char) => `\u2022 `);

const ConfirmText = ({ classes, label, value, onEdit, password, email }) => {
  const [hidden, setHidden] = useState(password);

  const { t } = useTranslation("IndividualSignUpSteps");

  return (
    <React.Fragment>
      <Text.Label>{label}</Text.Label>
      <div style={{ padding: !password && "15px 0px" }}>
        <Text.Body
          style={{ paddingRight: value.length > 0 && !password && "10px" }}
          className={classes.value}
        >
          {hidden ? convertPassword(value) : value}
        </Text.Body>
        {password && (
          <IconButton
            className={classes.showPassword}
            onClick={() => setHidden(!hidden)}
            disableRipple
          >
            {hidden ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
          </IconButton>
        )}
        <Text.Link
          className={classes.editLink}
          style={{ paddingLeft: value.length > 0 && "10px" }}
          onClick={onEdit}
        >
          {email && !value ? t("AddEmail") : t("Edit")}
        </Text.Link>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(ConfirmText);
