import React from "react";
import { Redirect, Link } from "worklete/router";
import { useWrite, useOnMount, useAuthApi } from "worklete/hooks";

const SignOutController = () => {
  const { signOut } = useAuthApi();
  const [send, sending, response, error] = useWrite(() => signOut());

  useOnMount(send);

  if (sending) {
    return <div>Logging Out...</div>;
  } else if (response) {
    return <Redirect to="signin" />;
  } else {
    const msg = (error && error.message) || "Problem Signing Out";
    return (
      <div>
        <div>{msg}</div>
        <Link to="player">Home</Link>
      </div>
    );
  }
};

export default SignOutController;
