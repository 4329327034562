import BodyText from "./BodyText";
import ErrorText from "./ErrorText";
import HeaderText from "./HeaderText";
import LabelText from "./LabelText";
import LinkText from "./LinkText";
import SubHeaderText from "./SubHeaderText";
import SuperHeaderText from "./SuperHeaderText";

const Text = {
  Body: BodyText,
  Error: ErrorText,
  Header: HeaderText,
  Label: LabelText,
  Link: LinkText,
  SubHeader: SubHeaderText,
  SuperHeader: SuperHeaderText,
};

export default Text;
