import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, StylesProvider } from "@material-ui/styles";
import theme from "../materialTheme";
import "./typography.css";

const ThemeManager = ({ children }) => {
  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeManager;
