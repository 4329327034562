const api_prefix = "/api/v1";

// OrganizationManager will set the `organization_id`` arg by default

const routes = {
  teams: {
    my_teams: "/organizations/:organization_id/my_teams",
    my_teams_worklete_score:
      "/organizations/:organization_id/my_teams/worklete_score",
    all_teams: "/organizations/:organization_id/teams",
    team: "/teams/:team_id",
    team_worklete_score: "/teams/:team_id/worklete_score",
    invites: "/teams/:team_id/invites",
  },
  admins: {
    admins: "/teams/:team_id/admins",
    admin: "/teams/:team_id/admins/:membership_id",
    potential_admins: "/teams/:team_id/admins/potential_admins",
  },
  memberships: {
    memberships: "/organizations/:organization_id/memberships/search",
    membership: "/memberships/:membership_id",
    admin_permission: "/memberships/:membership_id/admin_permission",
  },
  player: {
    my_course: "/player/my_course",
    course: "/player/courses/:course_id",
    course_update: "/player/courses/:course_id/user_course_statuses",
  },
};

export { api_prefix };

export default routes;
