import React, { useContext, useState, useMemo } from "react";
import { useUnmounted } from "worklete/hooks";

const defaultValue = {
  onActivate: () => {},
  onComplete: () => {},
  activated: false,
  default: true,
};

const StepButtonGroupContext = React.createContext(defaultValue);

StepButtonGroupContext.displayName = "StepButtonGroupContext";

const StepButtonGroup = ({ children }) => {
  const [activated, setActivated] = useState(false);

  const unmounted = useUnmounted();

  const value = useMemo(() => {
    return {
      onActivate: () => {
        if (!unmounted.current) setActivated(true);
      },
      onComplete: () => {
        if (!unmounted.current) setActivated(false);
      },
      activated,
      default: false,
    };
  }, [setActivated, activated, unmounted]);

  return (
    <StepButtonGroupContext.Provider value={value}>
      {children}
    </StepButtonGroupContext.Provider>
  );
};

const useButtonGroup = () => useContext(StepButtonGroupContext);

export { useButtonGroup };

export default StepButtonGroup;
