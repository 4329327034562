import React from "react";
import { useServerInfo } from "worklete/hooks";
import "./EnvBadge.css";
import { Link } from "react-router-dom";
import { useSession } from "App/Auth/session/SessionProviders";

const EnvBadge = () => {
  const { url_env, env, api_origin } = useServerInfo();
  const session = useSession();
  const { sending, signed_in, error_message } = session;

  if (url_env === "PROD") {
    return null;
  }

  const label =
    env === "PROD"
      ? "Production"
      : env === "STAGE"
      ? "Stage"
      : env === "LOCAL"
      ? "Local"
      : env === "CUSTOM"
      ? `Custom API Server: ${api_origin}`
      : "Unknown";

  const message =
    error_message || (sending ? "Signing In" : !signed_in ? "Signed Out" : "");

  return (
    <Link className="env-badge" to="/debug">
      {message && `${message} - `}
      {label}
    </Link>
  );
};

export default EnvBadge;
