import _ from "lodash";
import { useEffect } from "react";

const useLog = (...args) => {
  const value = _.last(args);
  useEffect(() => console.log(...args), [value]); // eslint-disable-line
  return value;
};

window.console["useLog"] = useLog;

export default useLog;

