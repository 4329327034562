import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import "./Text.scss";

const RouterLink = ({ className, ...rest }) => (
  <Link className={classNames("link-text", className)} {...rest} />
);

const ExternalLink = ({ className, children, url, ...rest }) => (
  <a
    className={classNames("link-text", className)}
    href={url}
    target={url.startsWith("mailto:") ? "_top" : "_blank"}
    rel="noopener noreferrer"
    {...rest}
  >
    {children}
  </a>
);

const ButtonLink = ({ className, ...rest }) => (
  <button
    className={classNames("link-text", "link-button", className)}
    {...rest}
  />
);

const DisabledLink = ({ className, ...rest }) => (
  <span className={classNames("link-text-disabled", className)} {...rest} />
);

const LinkText = (props) => {
  const { to, href, displayOnly, ...rest } = props;
  const url = to || href;

  return displayOnly ? (
    <DisabledLink {...rest} />
  ) : url ? (
    url.includes("://") ||
    url.startsWith("mailto:") ||
    url.startsWith("tel:") ? (
      <ExternalLink url={url} {...rest} />
    ) : (
      <RouterLink to={url} {...rest} />
    )
  ) : (
    <ButtonLink {...rest} />
  );
};

export default LinkText;
