import _ from "lodash";

const completionPercentage = (num_complete = 0, total = 0, max = 100) => {
  const percentage = (num_complete / total) * 100 || 0;
  const maxed = _.min([percentage, max]);
  const val = _.max([maxed, 0]).toFixed(0);
  return `${val}%`;
};

export { completionPercentage };
