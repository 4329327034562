import { format } from "date-fns";

const makeFormatter = (date_format) => (date, fallback = "--") => {
  if (!date) return fallback;

  const _date = typeof date === "string" ? new Date(date).valueOf() : date;

  try {
    const formatted = format(_date, date_format);
    return formatted === "Invalid Date" ? fallback : formatted;
  } catch (error) {
    console.log("DATE FORMAT ERROR: ", date, date_format, error);
    return fallback;
  }
};

const formatDate = makeFormatter("M/d/yy");

const shortDate = makeFormatter("M/d");

export { formatDate, shortDate };
