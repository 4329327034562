import React, { Fragment, useState } from "react";
import { useTranslation, useWrite } from "worklete/hooks";

import { errorMessage } from "worklete/format";
import { Text } from "worklete/components/";

import { requestPasswordReset } from "App/Auth/authApi";

import AuthHeader from "../AuthHeader";
import AuthButton from "../AuthButton";
import AuthTextField from "../AuthTextField";

const EnterUsernameForm = ({ contactSupport, returnToLogin, email }) => {
  const [username, setUsername] = useState("");

  const { t } = useTranslation();

  const [send, , response, error] = useWrite(() => {
    return requestPasswordReset(username);
  });

  const obfuscated_email = email || (response ? response.data.data.email : "");

  const errorMessaging = () => {
    if (error) {
      switch (error.status) {
        case 404:
          return (
            <Text.Error>
              {t("AccountRecoveryUsernameFormErrorNoUserName")}
              <Text.Link onClick={contactSupport}>
                {t("ContactSupport")}
              </Text.Link>
            </Text.Error>
          );
        case 428:
          return (
            <Text.Error>
              {t("AccountRecoveryUsernameFormErrorNoEmailOne")}
              <Text.Link onClick={contactSupport}>
                {t("ContactSupport")}
              </Text.Link>
              {t("AccountRecoveryUsernameFormErrorNoEmailTwo")}
            </Text.Error>
          );
        default:
          return errorMessage(error);
      }
    }
    return false;
  };

  if (obfuscated_email) {
    return (
      <Fragment>
        <AuthHeader
          superHeader={t("AccountRecoveryUsernameFormConfirmSHeader")}
          header={t("AccountRecoveryUsernameFormConfirmHeader")}
        />
        <Text.Body>{t("AccountRecoveryUsernameFormConfirmBody")}</Text.Body>
        <Text.SuperHeader style={{ paddingTop: 20 }}>
          {obfuscated_email}
        </Text.SuperHeader>
        <AuthButton onTimeout={returnToLogin} text={t("ReturnToLogin")} />
      </Fragment>
    );
  }
  return (
    <Fragment>
      <AuthHeader
        superHeader={t("AccountRecoveryUsernameFormSHeader")}
        header={t("AccountRecoveryUsernameFormHeader")}
      />
      <AuthTextField
        id="username"
        label={t("Username")}
        value={username}
        onChange={(un) => setUsername(un)}
        error={errorMessaging()}
      />
      <AuthButton text={t("Continue")} disabled={!username} onTimeout={send} />
      <div style={{ textAlign: "center", paddingTop: 40 }}>
        <Text.Link onClick={contactSupport}>
          {t("AccountRecoveryOptionsNoUsername")}
        </Text.Link>
      </div>
      <div style={{ textAlign: "center", paddingTop: 40 }}>
        <Text.Link onClick={returnToLogin}>{t("ReturnToLogin")}</Text.Link>
      </div>
    </Fragment>
  );
};

export default EnterUsernameForm;
