import _ from "lodash";
import React, { createContext, useContext, useMemo } from "react";

const OrganizationsContext = createContext([]);
const OrganizationsIndexContext = createContext({});

const OrgsProviders = ({ organizations = [], children }) => {
  const organizations_index = useMemo(() => {
    return _.keyBy(organizations, "id");
  }, [organizations]);

  return (
    <OrganizationsContext.Provider value={organizations}>
      <OrganizationsIndexContext.Provider value={organizations_index}>
        {children}
      </OrganizationsIndexContext.Provider>
    </OrganizationsContext.Provider>
  );
};

const useOrganizationsList = () => useContext(OrganizationsContext);

const useOrganizationById = (id) => {
  return useContext(OrganizationsIndexContext)[id] || {};
};

export { OrgsProviders, useOrganizationById, useOrganizationsList };
