import React, { useState, useReducer, useEffect } from "react";
import SignUpSteps from "./SignUpSteps";
import {
  useWrite,
  useAuthApi,
  useFetch,
  useDebounceValue,
} from "worklete/hooks";
import { errorMessage } from "worklete/format";
import { checkUsername, getInvitation } from "App/Auth/authApi";
import {
  getUsernameErrors,
  getPasswordErrors,
  getEmailErrors,
  getConfirmationErrors,
} from "../helpers/formValidationHelpers";

const defaultValues = {
  invite_code: "",
  username: "",
  password: "",
  email: "",
  confirmation: false,
  language: null,
};

const SignUpController = ({ classes, initialStep, initialValues }) => {
  const startValues = { ...defaultValues, ...initialValues };
  const startStep = initialStep || "InviteCodeScreen";

  const [step, setStep] = useState(startStep);

  const current_step = useDebounceValue(step, 1200);

  const [values, setValues] = useReducer((state, action) => {
    return { ...state, ...action };
  }, startValues);

  const {
    username,
    password,
    email,
    confirmation,
    language,
    invite_code,
  } = values;

  const [checkInvite, checking_invite, invite, invite_error, clear] = useWrite(
    async () => {
      const { data } = await getInvitation(invite_code);
      setStep("IntroScreen");
      return data;
    },
  );

  const AuthApi = useAuthApi();

  const [onSignUp, , , signup_error] = useWrite(() => {
    const first_name = (invite && invite.membership_first_name) || username;
    const last_name = (invite && invite.membership_last_name) || "";

    const data = {
      username,
      password,
      email,
      first_name,
      last_name,
      language,
      invite_code,
    };
    return AuthApi.signUp(data).then((resp) => {
      setStep("OutroScreen");
      return resp;
    });
  });

  const signup_error_message = errorMessage(signup_error);

  const debounced_username = useDebounceValue(username, 300);

  const [{ available, checked_name } = {}, loading] = useFetch(() => {
    return checkUsername(debounced_username).then((available) => {
      return {
        available,
        checked_name: debounced_username,
      };
    });
  }, [debounced_username]);

  useEffect(() => {
    if (invite_error) clear();
  }, [invite_code]); // eslint-disable-line

  const invite_error_message =
    invite_error &&
    (invite_error.status === 404
      ? "Unable to use that invite code"
      : "Unknown problem with that invite code");

  const checking = loading || username !== checked_name;

  const errors = {
    username: getUsernameErrors(username, available, checking),
    password: getPasswordErrors(password),
    email: getEmailErrors(email),
    confirmation: getConfirmationErrors(confirmation),
  };

  return (
    <SignUpSteps
      step={current_step}
      setStep={setStep}
      setValues={setValues}
      onSignUp={onSignUp}
      signup_error={signup_error_message}
      values={values}
      errors={errors}
      invite={invite}
      onCheckInvite={checkInvite}
      checking_invite={checking_invite}
      invite_error={invite_error_message}
    />
  );
};

export default SignUpController;
