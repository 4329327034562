import React from "react";
import { Route, Switch, Redirect } from "react-router";
import SignInController from "../AuthWorkflow/SignIn/SignInController";
import SignInResetPasswordController from "../AuthWorkflow/SignIn/SignInResetPasswordController";
import RecoverAccountController from "../AuthWorkflow/RecoverAccount/RecoverAccountController.js";
const unauthenticated_routes = [
  "/recover_account",
  "/reset_password_link",
  "/signin",
  "/singin",
  "/login",
];

const UnauthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path="/signin" SignInController>
        <SignInController />
      </Route>
      <Route path="/recover_account">
        <RecoverAccountController />
      </Route>
      <Route
        path="/reset_password_link"
        render={() => <SignInResetPasswordController />}
      />
      <Redirect from="/singin" to="/signin" />
      <Redirect from="/login" to="/signin" />

      <Redirect to="/signup" />
    </Switch>
  );
};

export { unauthenticated_routes };

export default UnauthenticatedRoutes;
