import _ from "lodash";
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";

import colors from "config/theming/colors";
import { useUnmounted } from "worklete/hooks";
import StepButtonGroup, { useButtonGroup } from "./StepButtonGroup";

const { number, func } = PropTypes;

const propTypes = {
  timeout: number,
  onInitialClick: func,
  onTimeout: func,
  //onComplete: func,
};

const defaultProps = {
  timeout: 1500,
  onInitialClick: _.noop,
  onTimeout: _.noop,
  //onComplete: _.noop,
};

const styles = (theme) => ({
  stepButtonCore: {
    fontSize: "16px",
    padding: "10px 20px",
    maxWidth: "500px",
    letterSpacing: "0.22em",
    border: "4px solid",
    fontWeight: 600,
  },
});

const StepButton = (props) => {
  const {
    classes,
    className,
    timeout,
    inactive,
    onInitialClick,
    onTimeout,
    style,
    to,
    ...rest
  } = props;

  const { onActivate, onComplete, activated } = useButtonGroup();

  const unmounted = useUnmounted();

  const [selected, setSelected] = useState(false);

  const getColor = (selected, disabled) => {
    if (selected) return colors.portland_rain;
    if (disabled) return colors.sf_fog;

    return null;
  };

  const stepButtonStyle = {
    color: getColor(selected, props.disabled),
    border: "4px solid",
    ...style,
  };

  const handleInitialClick = useCallback(() => {
    setSelected(true);
    onInitialClick();
    onActivate();
  }, [setSelected, onInitialClick, onActivate]);

  const handleTimeout = useCallback(() => {
    if (!unmounted.current) {
      setSelected(false);
      onComplete();
      onTimeout();
    }
  }, [onTimeout, setSelected, onComplete, unmounted]);

  const is_clickable =
    (to && !props.disabled) || (!selected && !activated && !inactive);

  const handleClick = useCallback(() => {
    if (is_clickable) {
      handleInitialClick();
      setTimeout(() => handleTimeout(), timeout);
    }
  }, [is_clickable, handleInitialClick, timeout, handleTimeout]);

  return (
    <Button
      className={classNames(classes.stepButtonCore, className)}
      onClick={handleClick}
      style={stepButtonStyle}
      variant={selected ? "contained" : "outlined"}
      color="secondary"
      component={to ? Link : undefined}
      to={to}
      {...rest}
    />
  );
};

StepButton.propTypes = propTypes;

StepButton.defaultProps = defaultProps;

export { StepButtonGroup };

export default withStyles(styles)(StepButton);
