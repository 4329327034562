import _ from "lodash";
import queryString from "query-string";
import { api_origin } from "App/System/server/server";

const stringify = (obj) => {
  return queryString.stringify(obj, { arrayFormat: "bracket" });
};

const replaceRoute = (route = "", args = {}) => {
  return _.map(route.split("/"), (part) => {
    if (part[0] !== ":") {
      return part;
    } else {
      const key = part.slice(1);
      const value = args[key];
      if (!value)
        throw new Error(
          `${route} requires an argument, got: ${value} for '${key}'`,
        );
      return value;
    }
  }).join("/");
};

const default_headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const request = (options) => {
  const { url, method, headers, params, data, ...rest } = options;

  const query_string = params ? "?" + stringify(params) : "";

  const full_url = api_origin + url + query_string;

  const fetch_options = {
    method: method,
    headers: { ...default_headers, ...headers },
    ...rest,
  };

  if (data) {
    fetch_options.body = JSON.stringify(data);
  }

  return fetch(full_url, fetch_options).then((resp) => {
    if (resp.ok) {
      if (resp.status === 204) {
        return {};
      } else {
        return resp.json();
      }
    } else {
      return resp
        .json()
        .then((errorJson) => {
          const { status, statusText } = resp;
          const err = { status, statusText, ...errorJson };
          throw err;
        })
        .catch((error) => {
          const { status, statusText } = resp;
          const err = { status, statusText, error };
          throw err;
        });
    }
  });
};

const makeApi = ({
  headers = {},
  api_prefix = "",
  routes = {},
  default_args = {},
}) => {
  const makeRequest = (route, method) => (options = {}) => {
    if (route.slice(0, 1) !== "/") {
      console.error(
        `API route Error: Routes should begin with a '/' character, check: ${route}`,
      );
    }
    const full_route = api_prefix + route;
    const { args: options_args, headers: option_headers, ...rest } = options;
    const combined_headers = { ...headers, ...option_headers };
    return request({
      method,
      url: replaceRoute(full_route, { ...default_args, ...options_args }),
      headers: combined_headers,
      ...rest,
    });
  };

  const makeRequests = (route) => {
    return {
      get: makeRequest(route, "GET"),
      post: makeRequest(route, "POST"),
      put: makeRequest(route, "PUT"),
      patch: makeRequest(route, "PATCH"),
      delete: makeRequest(route, "DELETE"),
    };
  };

  return _.mapValues(routes, (value) => {
    return _.isObject(value)
      ? _.mapValues(value, (route) => makeRequests(route))
      : makeRequests(value);
  });
};

export { request, makeApi };
