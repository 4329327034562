import addTranslations from "worklete/translations";

addTranslations("IndividualSignUpSteps", {
  AddEmail: {
    en: "Add Email",
  },
  AlreadyHave: {
    en: "Already have a Worklete account? ",
  },
  AndHaveRead: {
    en: " and have read and understand the ",
  },
  ByInvesting: {
    en:
      "By investing just 5-10 minutes every other week, you will learn how to default into stronger positions and avoid injury.",
  },
  Continue: {
    en: "Continue",
  },
  Edit: {
    en: "Edit",
  },
  Email: {
    en: "Email",
  },
  IAgreeToThe: {
    en: "I agree to the ",
  },
  Language: {
    en: "Language",
  },
  Password: {
    en: "Password",
  },
  PasswordMinimum: {
    en: "Password (minimum 6 characters)",
  },
  PrivacyPolicy: {
    en: "Privacy Policy",
  },
  ReadyToBegin: {
    en: "Ready to begin?",
  },
  TermsOfUse: {
    en: "Terms of Use",
  },
  UsernameVisible: {
    en: "Username (this will be visible to your team)",
  },
});
