const common = {
  Loading: {
    en: "Loading",
    es: "Cargando",
  },
  Hi: {
    en: "Hi",
    es: "Hola",
  },
  People: {
    en: "People",
    es: "Personas",
  },
  Scoreboard: {
    en: "Scoreboard",
    es: "Marcador",
  },
  Training: {
    en: "Training",
    es: "Entrenamiento",
  },
  Logout: {
    en: "Log Out",
    es: "Salir", //could also be "Cerrar sesión" but "Salir" is shorter and fits the UI
  },
  Close: {
    en: "Close",
    es: "Cerrar",
  },
  Continue: {
    en: "Continue",
  },
  Email: {
    en: "Email",
  },
  Username: {
    en: "Username",
  },
  Phone: {
    en: "Phone",
  },
  ReturnToLogin: {
    en: "Return to login page",
  },
  ContactSupport: {
    en: "Contact Support",
  },
  AccountRecoveryOptionSHeader: {
    en: "Trouble logging in?",
  },
  AccountRecoveryOptionsHeader: {
    en: "Recover your worklete account",
  },
  AccountRecoveryOptionsNoUsername: {
    en: "I don't know my username",
  },
  AccountRecoveryOptionsUsername: {
    en: "I know my username",
  },
  AccountRecoverySupportIntro: {
    en:
      "Having trouble accessing your Worklete account? We can help. Contact us via email, phone, or by using the help button in the bottom right corner of the screen.",
  },
  AccountRecoveryUsernameFormConfirmHeader: {
    en: "Check your email for a password reset link",
  },
  AccountRecoveryUsernameFormConfirmSHeader: {
    en: "Email Sent!",
  },
  AccountRecoveryUsernameFormConfirmBody: {
    en:
      "To help you get back into your worklete account, we sent instructions to the following email:",
  },
  AccountRecoveryUsernameFormErrorNoUserName: {
    en: "Could not find that username, check your spelling or ",
  },
  AccountRecoveryUsernameFormErrorNoEmailOne: {
    en: "Could not find an email for that username, ",
  },
  AccountRecoveryUsernameFormErrorNoEmailTwo: {
    en: " for assistance.",
  },
  AccountRecoveryUsernameFormHeader: {
    en: "what's your username?",
  },
  AccountRecoveryUsernameFormSHeader: {
    en: "Let's recover your account.",
  },
};

export default common;
