import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import {
  useWrite,
  useSession,
  useTranslation,
  useAuthApi,
} from "worklete/hooks";
import { Text } from "worklete/components";

import { changePassword } from "App/Auth/authApi";

import AuthButton from "../AuthButton";
import AuthTextField from "../AuthTextField";
import { getPasswordErrors } from "../helpers/formValidationHelpers";
import "./ResetPassword.i18n";

const UpdatePasswordForm = ({ onComplete }) => {
  const { __headers: headers } = useSession();
  const { refreshSession } = useAuthApi();

  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");

  const { t } = useTranslation("ResetPassword");

  const [send, sending, , error, clear] = useWrite(() => {
    return (
      changePassword(headers, pw1, pw2)
        .then(refreshSession)
        // Defering because the useWrite hook is causing a React warning
        // Long-term solution is to track mounted in the useWrite hook
        .then(() => _.defer(onComplete))
    );
  });

  const pw1Error = getPasswordErrors(pw1);

  const pw2Error =
    !pw1Error && pw1 !== pw2
      ? t("UpdatePasswordFormDoesNotMatch")
      : error
      ? t("UpdatePasswordFormSubmissionError")
      : getPasswordErrors(pw2);

  const buttonDisabled = !!sending || !pw1 || !pw2 || !!pw1Error || !!pw2Error;

  useEffect(() => {
    if (error) clear();
  }, [pw1, pw2]); // eslint-disable-line

  return (
    <Fragment>
      <Text.Label>{t("UpdatePasswordFormPasswordTextLabel")}</Text.Label>
      <AuthTextField
        id="password1"
        label={t("UpdatePasswordFormPasswordLabel")}
        type="password"
        value={pw1}
        onChange={(password) => setPw1(password)}
        error={pw1 && pw1Error}
      />
      <AuthTextField
        id="password2"
        label={t("UpdatePasswordFormConfirmationPasswordLabel")}
        type="password"
        value={pw2}
        onChange={(password) => setPw2(password)}
        error={pw2 && pw2Error}
      />
      <AuthButton
        text={t("UpdatePasswordFormContinueText")}
        disabled={buttonDisabled}
        onTimeout={send}
      />
    </Fragment>
  );
};

export default UpdatePasswordForm;
