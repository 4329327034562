import _ from "lodash";
import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/styles";

import { Text } from "worklete/components";

const styles = (theme) => ({
  inputRoot: {
    color: theme.palette.text.light,
    fontSize: "16px",
    marginRight: "20px",
    marginTop: "15px",
  },
  inputUnderline: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  inputError: {
    borderBottomColor: theme.palette.primary.main,
    paddingBottom: "2px",
    marginBottom: "-2px",
  },
  inputLabel: {
    color: theme.palette.secondary.main,
    "&$focused": {
      color: theme.palette.secondary.main,
    },
  },
  inputLabelShrink: {
    transform: "translate(0, 1.5px)",
    color: theme.palette.secondary.main,
  },
  focused: {}, //Intentionally given this name and left empty to allow nested JSS reference
  successIcon: {
    fontSize: "18px",
    color: theme.palette.text.light,
  },
  textField: {
    marginTop: "20px",
  },
  showPassword: {
    color: theme.palette.text.dark,
    fontSize: "16px",
    marginBottom: "3px",
  },
});

const AuthTextField = (props) => {
  const {
    classes,
    error,
    value,
    onChange,
    onEnter,
    onKeyUp,
    topInput,
    type,
    ...rest
  } = props;

  const [textVisible, setTextVisible] = useState(false);

  const showCheckIcon = !!(_.size(value) > 3 && !error);
  const showEyeIcon = _.size(value) >= 1;

  const inputProps = {
    autoCapitalize: "none",
    classes: {
      root: classes.inputRoot,
      underline: classes.inputUnderline,
      error: classes.inputError,
    },
    endAdornment: (
      <InputAdornment position="end">
        {type === "password" && showEyeIcon && (
          <IconButton
            className={classes.showPassword}
            onClick={() => setTextVisible(!textVisible)}
            disableRipple
          >
            <Icon>{textVisible ? "visibility_off" : "visibility"}</Icon>
          </IconButton>
        )}
        {showCheckIcon && (
          <Icon className={classes.successIcon}>check_circle</Icon>
        )}
      </InputAdornment>
    ),
  };

  const labelProps = {
    classes: {
      root: classes.inputLabel,
      shrink: classes.inputLabelShrink,
      focused: classes.focused,
    },
  };

  const textFieldStyle = {
    marginBottom: !error && "21px",
    marginTop: topInput ? "35px" : "15px",
  };

  const textType = type === "password" && textVisible ? "text" : type;

  const errorFragment = React.isValidElement(error) ? (
    error
  ) : (
    <Text.Error>{error}</Text.Error>
  );

  useEffect(() => {
    if (!showEyeIcon) {
      setTextVisible(false);
    }
  }, [showEyeIcon]);

  return (
    <React.Fragment>
      <TextField
        style={textFieldStyle}
        InputProps={inputProps}
        InputLabelProps={labelProps}
        error={!!error}
        value={value}
        type={textType}
        onChange={(e) => onChange(e.target.value)}
        onKeyUp={(e) => {
          onEnter && e.keyCode === 13 && onEnter(e);
          onKeyUp && onKeyUp(e);
        }}
        {...rest}
      />
      {errorFragment}
    </React.Fragment>
  );
};

export default withStyles(styles)(AuthTextField);
