import React from "react";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import "./SectionCard.scss";

const styles = (theme) => ({
  sectionCard: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    padding: "50px 0px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
  },
});

const SectionCard = ({
  classes,
  children,
  style,
  strongBadge,
  badge,
  bare,
}) => {
  const displayBadge = strongBadge ? (
    <span className="section-card--strong-badge">{strongBadge}</span>
  ) : badge ? (
    <span className="section-card--badge">{badge}</span>
  ) : null;

  return (
    <div className={classNames("section-card-wrapper", { "is-bare": bare })}>
      <Card className={classes.sectionCard} style={style}>
        {displayBadge}
        {children}
      </Card>
    </div>
  );
};

export default withStyles(styles)(SectionCard);
