import _ from "lodash";

const language_codes = ["en", "es"];

/* Worklete data is stored in the format

myToken: {
  en: "My Token",
  fr: "Mon Token"
}

but i18n requires preloaded data in the format:

i18next.init({
  resources: {
    en: {
      namespace1: {
        key: "hello from namespace 1",
      },
      namespace2: {
        key: "hello from namespace 2",
      },
    },
    de: {
      namespace1: {
        key: "hallo von namespace 1",
      },
      namespace2: {
        key: "hallo von namespace 2",
      },
    },
  },
}); */

const formatForPreload = (namespace, object) => {
  const resources = {};

  _.each(language_codes, (lang) => {
    resources[lang] = {};
    resources[lang][namespace] = {};

    _.each(object, (values, token) => {
      const value = values[lang];
      if (value) {
        resources[lang][namespace][token] = value;
      }
    });
  });

  return resources;
};

/* Worklete data is stored in the format

myToken: {
  en: "My Token",
  fr: "Mon Token"
}

but i18n loads dynamic data in the format:

i18next.init();
i18next.addResourceBundle('en', 'namespace1', {
  key: 'hello from namespace 1'
}); */

const formatForDynamicLoad = (object) => {
  const resources = {};

  _.each(language_codes, (lang) => {
    resources[lang] = {};

    _.each(object, (values, token) => {
      const value = values[lang];
      if (value) {
        resources[lang][token] = value;
      }
    });
  });

  return resources;
};

export { formatForDynamicLoad, formatForPreload };
