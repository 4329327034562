import React, { forwardRef } from "react";
import classNames from "classnames";
import "./AdminButton.scss";

const AdminButton = forwardRef((props, ref) => {
  const { primary, dangerous, active, hover, compact, ...rest } = props;

  const typeClassName = dangerous
    ? "is-dangerous"
    : primary
    ? "is-primary"
    : "is-default";

  return (
    <button
      {...rest}
      ref={ref}
      className={classNames("admin-button", typeClassName, {
        "is-disabled": props.disabled,
        "is-active": active,
        "is-hover": hover,
        "is-compact": compact,
      })}
    />
  );
});

export default AdminButton;
