import { useRef, useEffect } from "react";

import { focusFirst } from "./focusUtils";

const useFocusInside = (value, passedRef) => {
  const val = !!value;
  const defaultRef = useRef();
  const ref = passedRef || defaultRef;

  useEffect(() => {
    if (val) setTimeout(() => focusFirst(ref.current));
  }, [val]); // eslint-disable-line

  return ref;
};

export default useFocusInside;
