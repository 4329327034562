import _ from "lodash";
import { useMemo } from "react";

const default_user = {};

const useSessionFormatting = (headers, data = {}, sending, error = {}) => {
  const organizations = data.organizations || [];
  const memberships = data.memberships || [];
  const raw_user = data.user || default_user;
  const error_message = error.message;

  const user = useMemo(() => {
    const name = _.compact([raw_user.first_name, raw_user.last_name]).join(" ");
    return { ...raw_user, name };
  }, [raw_user]);

  const session = useMemo(() => {
    const signed_in = !!(headers && headers["access-token"]);
    return {
      sending,
      signed_in,
      user_id: user.id,
      __headers: headers,
      memberships,
      organizations,
      error_message,
    };
  }, [sending, headers, memberships, organizations, user, error_message]);

  return [session, user];
};

export default useSessionFormatting;
