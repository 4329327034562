import _ from "lodash";

const settings_config = {
  language_code: {
    label: "Language",
    description: "Overrides the language setting for the current membership",
    type: "string",
    default_value: "",
  },
  localization_development_mode: {
    label: "Localization Development Mode",
    description: "console.log unused keys",
    type: "boolean",
    default_value: false,
  },
  enable_debug_routes: {
    label: "Enable Debug Routes",
    description: "Allow a user to reach the debug routes",
    type: "boolean",
    default_value: false,
  },
};

const settings_defaults = _.mapValues(settings_config, "default_value");

export { settings_defaults };

export default settings_config;
