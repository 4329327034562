import addTranslations from "worklete/translations";

addTranslations("SignUpSteps", {
  AllGood: {
    en: "All good!",
  },
  AllSetUp: {
    en: "You're all set up!",
  },
  EmpoweringYou: {
    en: "Empowering you to work like an athlete",
  },
  GetStarted: {
    en: "Get started!",
  },
  GoBack: {
    en: "Go Back",
  },
  JoinYourTeam: {
    en: "Join your team on Worklete!",
  },
  LetsDoubleCheck: {
    en: "Let's double check.",
  },
  LetsGetMoving: {
    en: "Let's get moving!",
  },
  LetsGo: {
    en: "Let's go!",
  },
  LetsGoFirstName: {
    en: "Let's go, {{first_name}}!",
  },
  NotYou: {
    en: "Not {{first_name}}? ",
  },
  OrgAndWorklete: {
    en: "{{organization_name}} + Worklete",
  },
  PleaseConfirm: {
    en: "Please confirm your account info:",
  },
  PleaseCreate: {
    en: "Please create your Worklete account:",
  },
  PleaseEnter: {
    en: "Please Enter your Invite Code:",
  },
  PreferredLanguage: {
    en: "What is your preferred language?",
  },
  StrongHabits: {
    en: "Strong habits are developed over time",
  },
  WeUseEmail: {
    en:
      "We use email to send you helpful training reminders and team status reports.",
  },
  WelcomeFirstName: {
    en: "Welcome to the team, {{ first_name }}!",
  },
  WelcomeToTheTeam: {
    en: "Welcome to the team!",
  },
});
