import _ from "lodash";

const parseMembership = (session = {}, user = {}, active_oid) => {
  const worklete_employee = !!user.worklete_employee;
  const memberships = session.memberships || [];
  const teams = user.team_admin_assignments || [];

  // show a sensible default membership for when the user is a worklete_employee.
  // if worklete_admin === true then also we make org_admin === true.
  //
  const base_membership = worklete_employee
    ? { organization_id: active_oid }
    : _.find(memberships, { organization_id: active_oid }) || {};

  const admin_teams = _.filter(teams, { organization_id: active_oid });

  const org_admin = base_membership.org_admin;
  const has_org_admin_or_higher = worklete_employee || org_admin;
  const has_people_tab = has_org_admin_or_higher;
  const has_scoreboard_tab =
    worklete_employee || org_admin || _.size(admin_teams) > 0;

  return {
    admin_teams,
    has_org_admin_or_higher,
    has_people_tab,
    has_scoreboard_tab,
    ...base_membership,
  };
};

export default parseMembership;
