import _ from "lodash";
import React, { useContext, createContext, useMemo, useCallback } from "react";
import queryString from "query-string";
import { withRouter } from "react-router";

const QueryParamsContext = createContext({});

const useQueryParams = () => useContext(QueryParamsContext);

const QueryParamMethodsContext = createContext({});

const useQueryParamMethods = () => useContext(QueryParamMethodsContext);

const parse = (str) => queryString.parse(str, { arrayFormat: "bracket" });

const stringify = (obj) =>
  queryString.stringify(obj, { arrayFormat: "bracket" });

const searchify = (params) =>
  _.isEmpty(params) ? "" : "?" + stringify(params);

const QueryParamsProvider = ({ query_params, methods, children }) => {
  return (
    <QueryParamMethodsContext.Provider value={methods}>
      <QueryParamsContext.Provider value={query_params}>
        {children}
      </QueryParamsContext.Provider>
    </QueryParamMethodsContext.Provider>
  );
};

const QueryParamsManager = ({ match, location, history, children }) => {
  const { search, pathname } = location;

  const params_string = search.slice(1);

  const query_params = useMemo(() => {
    return parse(params_string);
  }, [params_string]);

  const removeRouteOid = useCallback(() => {
    const search = searchify(_.omit(query_params, ["organization_id"]));
    history.replace({ pathname, search });
  }, [history, pathname, query_params]);

  const methods = useMemo(() => {
    return { removeRouteOid };
  }, [removeRouteOid]);

  return (
    <QueryParamsProvider query_params={query_params} methods={methods}>
      {children}
    </QueryParamsProvider>
  );
};

const Manager = withRouter(QueryParamsManager);

export {
  Manager as QueryParamsManager,
  QueryParamsProvider,
  useQueryParams,
  useQueryParamMethods,
};
