import { useReducer, useCallback } from "react";
import useDebounceValue from "../useDebounceValue";

const useFilters = (options = {}) => {
  const reducer = (state, action) => {
    const { type, ...rest } = action;

    switch (type) {
      case "set":
        return { ...state, ...rest };
      case "set-query":
        return { ...state, page: 1, query: action.query };
      case "set-page":
        return { ...state, page: Math.max(action.page, 1) };
      case "next-page":
        return { ...state, page: state.page + 1 };
      case "previous-page":
        return { ...state, page: Math.max(1, state.page - 1) };
      case "set-filters":
        return { ...state, page: 1, filters: { ...action.filters } };
      case "add-filters":
        const new_filters = { ...state.filters, ...action.filters };
        return { ...state, page: 1, filters: new_filters };
      case "reset-filters":
        return { page: 1, query: "", filters: { ...options.filters } };
      case "set-sort-prop":
        if (action.sortProp === state.sortProp) {
          return { ...state, sortReverse: !state.sortReverse, page: 1 };
        } else {
          return { ...state, sortProp: action.sortProp, page: 1 };
        }
      case "set-sort-reverse":
        return { ...state, sortReverse: action.sortReverse, page: 1 };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    page: options.page || 1,
    query: options.query || "",
    filters: options.filters || {},
    sortProp: options.sortProp || "",
    sortReverse: options.sortReverse || false,
  });

  const { page, query, filters, sortProp, sortReverse } = state;

  const debounced_query = useDebounceValue(query);

  const set = useCallback((props) => dispatch({ ...props, type: "set" }), []);

  const setSortProp = useCallback((sortProp) => {
    dispatch({ type: "set-sort-prop", sortProp });
  }, []);

  const setSortReverse = useCallback((sortReverse) => {
    dispatch({ type: "set-sort-reverse", sortReverse });
  }, []);

  const setQuery = useCallback((query) => {
    dispatch({ type: "set-query", query });
  }, []);

  const setPage = useCallback((page) => {
    dispatch({ type: "set-page", page });
  }, []);

  const nextPage = useCallback(() => {
    dispatch({ type: "next-page" });
  }, []);

  const previousPage = useCallback(() => {
    dispatch({ type: "previous-page" });
  }, []);

  const setFilters = useCallback((filters = {}) => {
    dispatch({ type: "set-filters", filters });
  }, []);

  const addFilters = useCallback((filters = {}) => {
    dispatch({ type: "add-filters", filters });
  }, []);

  const resetFilters = useCallback(() => {
    dispatch({ type: "reset-filters" });
  }, []);

  const typing = query !== debounced_query;

  return {
    set,
    page,
    setPage,
    nextPage,
    previousPage,
    query,
    setQuery,
    debounced_query,
    typing,
    filters,
    setFilters,
    addFilters,
    resetFilters,
    sortProp,
    setSortProp,
    sortReverse,
    setSortReverse,
  };
};

export default useFilters;
