import React from "react";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  genericText: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.light,
  },
});

const BodyText = ({ classes, className, children, style }) => {
  return (
    <Typography
      className={classNames(classes.genericText, className)}
      style={style}
      variant="body2"
    >
      {children}
    </Typography>
  );
};

export default withStyles(styles)(BodyText);
