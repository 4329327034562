import _ from "lodash";
import React from "react";
import { Redirect } from "worklete/router";
import { useTranslation } from "worklete/hooks";

import AuthButton from "../AuthButton";
import AuthLayout from "../AuthLayout";
import AuthHeader from "../AuthHeader";

import InviteCodeScreen from "./steps/InviteCodeScreen";
import ProfileForm from "./steps/ProfileForm";
import SelectLanguage from "./steps/SelectLanguage";
import ConfirmPage from "./steps/ConfirmPage/ConfirmPage";
import OutroScreen from "./steps/OutroScreen";

import { ErrorBoundary, Text } from "worklete/components/";

import img1 from "./images/1Screen-training.png";
import img2 from "./images/2Screen-keg.png";
import img3 from "./images/3Screen-truck.png";
import img4 from "./images/4Screen-truck.png";
import img5 from "./images/5Screen-forklift.png";
import img6 from "./images/6Screen-training.png";

import "./SignUpSteps.i18n";

const SignUpSteps = (props) => {
  const {
    step,
    setStep,
    setValues,
    onSignUp,
    signup_error,
    values = {},
    errors,
    onCheckInvite,
    invite = {},
    checking_invite,
    invite_error,
  } = props;

  const { t } = useTranslation("SignUpSteps");

  const { membership_first_name, organization_name } = invite;

  const introMessage = (first_name) => {
    return first_name ? t("LetsGoFirstName", { first_name }) : t("LetsGo");
  };

  const welcomeMessage = (first_name) => {
    return first_name
      ? t("WelcomeFirstName", { first_name })
      : t("WelcomeToTheTeam");
  };

  switch (step) {
    case "InviteCodeScreen":
      return (
        <AuthLayout backgroundImage={img1}>
          <AuthHeader
            superHeader={t("JoinYourTeam")}
            header={t("PleaseEnter")}
          />
          <InviteCodeScreen
            invite_code={values.invite_code}
            onInviteCodeChange={(invite_code) => setValues({ invite_code })}
            checking_invite={checking_invite}
            onSubmit={onCheckInvite}
            invite_error={invite_error}
          />
        </AuthLayout>
      );
    case "IntroScreen":
      return (
        <AuthLayout backgroundImage={img2}>
          <AuthHeader
            superHeader={t("OrgAndWorklete", { organization_name })}
            header={t("EmpoweringYou")}
          />
          <AuthButton
            text={introMessage(membership_first_name)}
            onInitialClick={() => setStep("ProfileForm")}
            style={{ display: "inline-block", overflowWrap: "break-word" }}
          />

          <Text.Body style={{ textAlign: "center", paddingTop: 20 }}>
            <span style={{ paddingRight: 5 }}>
              {t("NotYou", { first_name: membership_first_name || "You" })}
            </span>
            <Text.Link onClick={() => setStep("InviteCodeScreen")}>
              {t("GoBack")}
            </Text.Link>
          </Text.Body>
        </AuthLayout>
      );

    case "ProfileForm":
      const canSubmit =
        !_.some(
          _.pick(errors, ["username", "password", "email", "confirmation"]),
        ) && _.every(_.pick(values, ["username", "password", "confirmation"]));

      return (
        <AuthLayout backgroundImage={img3} extraLong>
          <AuthHeader
            superHeader={t("LetsGetMoving")}
            header={t("PleaseCreate")}
          />
          <ProfileForm
            values={values}
            errors={errors}
            setValues={setValues}
            canSubmit={canSubmit}
            onSubmit={() => {
              // if language is set,
              // then we are editing from the confirm page and should go back
              setStep(values.language ? "ConfirmPage" : "SelectLanguage");
            }}
          />
          <Text.Body style={{ padding: "35px 50px", textAlign: "center" }}>
            {t("WeUseEmail")}
          </Text.Body>
        </AuthLayout>
      );
    case "SelectLanguage":
      return (
        <AuthLayout backgroundImage={img4}>
          <AuthHeader
            superHeader={welcomeMessage(membership_first_name)}
            header={t("PreferredLanguage")}
          />
          <SelectLanguage
            language={values.language}
            onSelect={(code) => {
              setValues({ language: code });
              setStep("ConfirmPage");
            }}
          />
        </AuthLayout>
      );
    case "ConfirmPage":
      return (
        <AuthLayout backgroundImage={img5} mediumLong>
          <AuthHeader
            superHeader={t("LetsDoubleCheck")}
            header={t("PleaseConfirm")}
          />
          <ConfirmPage
            values={values}
            onProfileChange={() => setStep("ProfileForm")}
            onLanguageChange={() => setStep("SelectLanguage")}
          />

          {signup_error && (
            <Text.Error style={{ padding: "12px 0" }}>
              {signup_error}
            </Text.Error>
          )}

          <AuthButton text={t("AllGood")} onInitialClick={onSignUp} />
        </AuthLayout>
      );
    case "OutroScreen":
      return (
        <AuthLayout backgroundImage={img6}>
          <AuthHeader superHeader={t("AllSetUp")} header={t("StrongHabits")} />
          <OutroScreen />
          <AuthButton
            text={t("GetStarted")}
            onInitialClick={() => setStep("Done")}
          />
        </AuthLayout>
      );
    case "Done":
      return <Redirect to="/training" />;
    default:
      return <ErrorBoundary />;
  }
};

export default SignUpSteps;
