import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "worklete/hooks";
import { useWindowSize } from "worklete/hooks";

import { StepButton } from "worklete/components";

const LogoutButton = ({ history }) => {
  const { t } = useTranslation();
  const { is_mobile } = useWindowSize();

  const logoutStyle = {
    borderWidth: "2px",
    fontSize: is_mobile ? "10px" : "12px",
    padding: is_mobile ? "2px 8px" : "4px 12px",
  };

  return (
    <StepButton style={logoutStyle} onTimeout={() => history.push("/signout")}>
      {t("Logout")}
    </StepButton>
  );
};

export default withRouter(LogoutButton);
