import React from "react";
import Icon from "@material-ui/core/Icon";
import _ from "lodash";
import { Text } from "worklete/components";
import {
  useWindowSize,
  useUser,
  useToggle,
  useTranslation,
} from "worklete/hooks";
import LogoutButton from "./LogoutButton";
import ProfileCard from "./ProfileCard/ProfileCard";
import classNames from "classnames";
import "./AppHeader.scss";

const AppHeader = ({ classes }) => {
  const { username } = useUser();

  const { height, is_mobile } = useWindowSize();

  const { t } = useTranslation();

  const [is_open, onOpen, onClose] = useToggle();

  const compact = height < 600 || is_mobile;

  const styles = {
    display: "inline-block",
    verticalAlign: "middle",
  };

  const usernameStyles = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: compact && "180px",
    fontSize: compact && _.size(username) >= 12 ? "12px" : "14px",
    marginRight: 8,
  };

  const name_link = (
    <Text.Link onClick={onOpen} style={{ lineHeight: 1 }}>
      <Icon style={{ ...styles, marginRight: 4 }}>{"expand_more"}</Icon>
      <span style={{ ...styles, ...usernameStyles }}>
        {`${t("Hi")}, ${username}`}
      </span>
    </Text.Link>
  );

  return (
    <div className={classNames("app-header", { "is-sticky": height > 400 })}>
      <div
        className="app-header--content-wrap"
        style={{
          paddingTop: compact ? 8 : 16,
          paddingBottom: compact ? 8 : 16,
          paddingRight: is_mobile ? 16 : 50,
        }}
      >
        <span
          className="app-header--name"
          style={{ paddingRight: compact ? 8 : 42 }}
        >
          {name_link}
        </span>

        <LogoutButton />
      </div>
      <ProfileCard is_open={is_open} onClose={onClose} />
    </div>
  );
};

export default AppHeader;
