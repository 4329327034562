import React from "react";
import { Link } from "react-router-dom";
import "./AdminLink.css";

const AdminLink = ({ href, to, onClick, children, ...rest }) => {
  return onClick ? (
    <button onClick={onClick} {...rest} className="admin-link">
      {children}
    </button>
  ) : href ? (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
      className="admin-link"
    >
      {children || href}
    </a>
  ) : (
    <Link to={to} {...rest} className="admin-link">
      {children || to}
    </Link>
  );
};

export default AdminLink;
