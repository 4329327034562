import React, { createContext, useContext } from "react";

const LanguageCodeContext = createContext("en");

LanguageCodeContext.displayName = "LanguageCodeContext";

const LocalizationProviders = ({ language_code, children }) => {
  return (
    <LanguageCodeContext.Provider value={language_code}>
      {children}
    </LanguageCodeContext.Provider>
  );
};

const useLanguageCode = () => useContext(LanguageCodeContext);

export { LocalizationProviders, useLanguageCode };
