import React from "react";
import { Text } from "worklete/components";

const SearchTableError = ({ errorMessage }) => (
  <Text.Error style={{ fontSize: "16px", padding: "15px 20px 25px 50px" }}>
    {errorMessage}
  </Text.Error>
);

export default SearchTableError;
