import React from "react";
import useSettingsManagement from "./useSettingsManagement";
import { SettingsProvider } from "./SettingsProvider";
import settings_config from "./settings_config";

const SettingsManagement = ({ children }) => {
  const { settings, setSetting } = useSettingsManagement(settings_config);

  return (
    <SettingsProvider settings={settings} setSetting={setSetting}>
      {children}
    </SettingsProvider>
  );
};

export default SettingsManagement;
