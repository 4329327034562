import React, { Fragment } from "react";
import { useTranslation } from "worklete/hooks";

import { Text } from "worklete/components/";

import AuthHeader from "../AuthHeader";
import AuthButton from "../AuthButton";

const ChooseRecoveryOptionForm = ({ returnToLogin }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <AuthHeader header={t("ContactSupport")} />
      <Text.Body>{t("AccountRecoverySupportIntro")}</Text.Body>
      <Text.Body style={{ paddingTop: 20 }}>
        {t("Email")}:
        <Text.Link to="mailto:support@worklete.com">
          {" support@worklete.com"}
        </Text.Link>
      </Text.Body>
      <Text.Body>
        {t("Phone")}:
        <Text.Link to="tel:844-973-5383">
          {" 844-WRKLETE (844-973-5383)"}
        </Text.Link>
      </Text.Body>
      <AuthButton onTimeout={returnToLogin} text={t("ReturnToLogin")} />
    </Fragment>
  );
};

export default ChooseRecoveryOptionForm;
