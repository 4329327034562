import React from "react";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  errorText: {
    color: theme.palette.error.main,
  },
});

const ErrorText = ({ classes, className, style, children }) => {
  return (
    <Typography
      className={classNames(classes.errorText, className)}
      style={style}
      variant="body2"
    >
      {children}
    </Typography>
  );
};

export default withStyles(styles)(ErrorText);
