import { useEffect } from "react";

const ZeWebWidget = (val) => {
  typeof window.zE === "function" && window.zE("webWidget", val);
};

const useHideZendeskWidget = () => {
  useEffect(() => {
    ZeWebWidget("hide");
    return () => ZeWebWidget("show");
  }, []);
};

export default useHideZendeskWidget;
