import React, { useEffect } from "react";
import { Redirect } from "worklete/router";
import { useWrite, useAuthApi, useSession } from "worklete/hooks";
import { useQueryParams } from "../../QueryParamsManager/QueryParamsManager";
import Message from "Sections/WorkleteAdmin/components/Message/Message";

const SignInResetPasswordController = () => {
  const { signed_in } = useSession();
  const { signIn } = useAuthApi();
  const { uid, token } = useQueryParams();
  const [send, , response, error] = useWrite(
    () =>
      signIn({
        username: uid,
        password: decodeURIComponent(token),
      }),
    [uid, token],
  );

  useEffect(() => {
    if (uid && token) {
      send();
    }
  }, []); // eslint-disable-line

  return !!(response || signed_in) ? (
    // Redirects the user to the training page if there's a successful response
    // and the user has signed in. The password flag will then take effect.
    <Redirect to="/training" />
  ) : error ? (
    // Displays error message if there's an error signing in.
    <Message message={`Error signing in.`} />
  ) : !!(uid && token) ? (
    // Notifies the user that the page is loading if sending is in progress.
    <Message message={`Signing in...`} />
  ) : (
    // Redirects the user to the training page if they enter the route by mistake
    // without the correct parameters entered (which the link would contain).
    <Redirect to="/training" />
  );
};

export default SignInResetPasswordController;
