import { env, is_development } from "App/System/server/server";

const developmentError = (err) => {
  if (is_development) {
    console.error("Settings Error: ", err);
  }
};

const wait = (time = 100) => new Promise((res) => setTimeout(res, time));

const SETTINGS_KEY = "WORKLETE_SETTINGS_" + env;

const syncReadSettings = () => {
  try {
    const stored_value = localStorage.getItem(SETTINGS_KEY);
    if (stored_value) {
      return JSON.parse(stored_value);
    } else {
      return {};
    }
  } catch (e) {
    developmentError(e);
    return {};
  }
};

const syncWriteSettings = (settings) => {
  try {
    const value = JSON.stringify(settings);
    localStorage.setItem(SETTINGS_KEY, value);
  } catch (err) {
    developmentError(err);
    throw err;
  }
};

const syncWriteSetting = (existing_settings, key, value) => {
  const settings = { ...existing_settings };

  if (value === undefined) {
    delete settings[key];
  } else {
    settings[key] = value;
  }
  syncWriteSettings(settings);
};

const syncClearSettings = () => localStorage.removeItem(SETTINGS_KEY);

const initial_settings = syncReadSettings();

// async methods (which simulate having an network API for this data)

const fetchSettings = async () => {
  await wait();
  return syncReadSettings();
};

const saveSetting = async (key, value) => {
  await wait();
  const settings = syncReadSettings();
  syncWriteSetting(settings, key, value);
};

const clearSettings = async () => {
  await wait();
  syncClearSettings();
};

export { initial_settings, fetchSettings, saveSetting, clearSettings };
