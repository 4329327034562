import React, { useState } from "react";

import { ErrorBoundary } from "worklete/components";
import { withRouter } from "react-router-dom";

import AuthLayout from "../AuthLayout";

import ChooseRecoveryOptionForm from "./ChooseRecoveryOptionForm";
import EnterUsernameForm from "./EnterUsernameForm";
import ContactSupportForm from "./ContactSupportForm";

const RecoverAccountController = ({ initialStep, history, email }) => {
  const [workflowStep, setWorkflowStep] = useState(
    initialStep || "ChooseRecoveryOption",
  );

  const contactSupport = () => setWorkflowStep("ContactSupport");
  const returnToLogin = () => history.push("/");

  switch (workflowStep) {
    case "ChooseRecoveryOption":
      return (
        <AuthLayout>
          <ChooseRecoveryOptionForm
            enterUsername={() => setWorkflowStep("EnterUsername")}
            returnToLogin={returnToLogin}
            contactSupport={contactSupport}
          />
        </AuthLayout>
      );
    case "EnterUsername":
      return (
        <AuthLayout>
          <EnterUsernameForm
            returnToLogin={returnToLogin}
            contactSupport={contactSupport}
            email={email}
          />
        </AuthLayout>
      );
    case "ContactSupport":
      return (
        <AuthLayout>
          <ContactSupportForm returnToLogin={returnToLogin} />
        </AuthLayout>
      );
    default:
      return <ErrorBoundary />;
  }
};

export default withRouter(RecoverAccountController);
