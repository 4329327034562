import _ from "lodash";
import { useMemo } from "react";
import { useFetch } from "worklete/hooks";
import * as AuthApi from "../authApi";

const useOrgsManagement = (session, user) => {
  const user_id = session.user_id;
  const headers = session.__headers || {};
  const token = headers["access-token"];
  const worklete_employee = user.worklete_employee;
  const session_orgs = session.organizations;

  const [fetched_orgs] = useFetch(() => {
    return worklete_employee
      ? AuthApi.fetchOrganizations(session.__headers).then(({ data }) => data)
      : Promise.resolve(session_orgs);
  }, [worklete_employee, user_id, token]);

  const orgs = useMemo(() => {
    return !_.isEmpty(fetched_orgs) ? fetched_orgs : session_orgs || [];
  }, [fetched_orgs, session_orgs]);

  return [orgs];
};

export default useOrgsManagement;
