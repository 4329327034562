import { useEffect } from "react";
import { saveOrganizationId } from "../authStorage";

const useSaveOrg = (oid, token) => {
  useEffect(() => {
    saveOrganizationId(oid && token ? oid : null);
  }, [oid, token]);
};

export default useSaveOrg;
