import React, { useContext } from "react";

import server from "./server";

const url_env = server.url_env;

const label =
  url_env === "STAGE" ? "Stage" : url_env === "LOCAL" ? "Local" : "Unknown";

if (url_env !== "PROD") {
  document.title = `${label} ${document.title}`;
}

const ServerInfoContext = React.createContext({});

const ServerInfoManager = ({ children }) => {
  return (
    <ServerInfoContext.Provider value={server}>
      {typeof children === "function" ? children(server) : children}
    </ServerInfoContext.Provider>
  );
};

const useServerInfo = () => useContext(ServerInfoContext);

const ServerInfoConsumer = ServerInfoContext.Consumer;

export { ServerInfoManager, useServerInfo, ServerInfoConsumer };
