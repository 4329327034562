import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { useUser } from "worklete/hooks";
import { WorkleteAdmin } from "Sections/Sections";
import RequireOrganizationController from "./RequireOrganizationController";

const AuthenticatedRoutes = () => {
  const { worklete_employee } = useUser();

  return (
    <Switch>
      <Route path="/worklete-admin">
        {worklete_employee ? <WorkleteAdmin /> : <Redirect to="/training" />}
      </Route>

      <Route>
        <RequireOrganizationController />
      </Route>
    </Switch>
  );
};

export default AuthenticatedRoutes;
