import React from "react";

import { withStyles } from "@material-ui/styles";
import { useTranslation } from "worklete/hooks";

import { Text } from "worklete/components";

import "./IndividualSignUpSteps.i18n";

const styles = (theme) => ({
  outroText: {
    lineHeight: "1.5em",
    margin: "10px 0px",
    paddingRight: "10px",
  },
  textContainer: {
    maxWidth: "500px",
  },
});

const OutroScreen = ({ classes }) => {
  const { t } = useTranslation("IndividualSignUpSteps");

  return (
    <div className={classes.textContainer}>
      <Text.Body className={classes.outroText}>{t("ByInvesting")}</Text.Body>
      <Text.Body className={classes.outroText}>{t("ReadyToBegin")}</Text.Body>
    </div>
  );
};

export default withStyles(styles)(OutroScreen);
