import React from "react";
import { withStyles } from "@material-ui/styles";
import { useWindowSize } from "worklete/hooks";
import colors from "config/theming/colors";

import { ReactComponent as Logo } from "worklete/assets/logo.svg";

const styles = (theme) => ({
  AuthLayout: {
    backgroundSize: "100%",
    height: "100vh",
    width: "100%",
    overflowY: "auto",
    display: "flex",
  },
  signupCard: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
    color: "#FFF",
    paddingRight: "35px",
    paddingLeft: "60px",
    width: "500px",
    height: "650px",
    marginLeft: "20px",
    marginTop: "30px",
    borderRadius: "1%",
  },
  logo: {
    width: "48px",
    padding: "50px 0px",
    marginBottom: "15px",
  },
});

const AuthLayout = ({
  children,
  classes,
  backgroundImage,
  extraLong,
  mediumLong,
}) => {
  const { is_desktop, is_mobile } = useWindowSize();

  const layoutStyle = {
    backgroundColor: is_desktop ? colors.dark_blue : colors.portland_rain,
    backgroundImage:
      is_desktop && !!backgroundImage && `url("${backgroundImage}")`,
    backgroundSize: "cover",
    justifyContent: !is_desktop && "center",
  };

  const cardStyle = {
    marginTop: is_mobile && "0px",
    paddingRight: is_mobile && "20px",
    paddingLeft: is_mobile && "30px",
    height: extraLong ? "900px" : mediumLong && "800px",
  };

  return (
    <div className={classes.AuthLayout} style={layoutStyle}>
      <div className={classes.signupCard} style={cardStyle}>
        <div>
          <Logo className={classes.logo} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default withStyles(styles)(AuthLayout);
