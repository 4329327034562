import React from "react";
import { Text, SectionCard, SearchTable } from "worklete/components";

const OrganizationPicker = ({ oid, organizations, onOrganizationChange }) => {
  const columns = [
    {
      label: "Organization",
      genContent: ({ name, id }) => {
        return (
          <Text.Link onClick={() => onOrganizationChange(id)}>{name}</Text.Link>
        );
      },
    },
    {
      label: "ID",
      genContent: ({ id }) => <Text.Body>{id}</Text.Body>,
    },
  ];

  return (
    <div
      style={{
        padding: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <SectionCard style={{ maxWidth: 600 }}>
        <SearchTable columns={columns} data={organizations} />
      </SectionCard>
    </div>
  );
};

export default OrganizationPicker;
