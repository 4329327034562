import React, { Fragment } from "react";
import { useTranslation } from "worklete/hooks";

import { StepButtonGroup } from "worklete/components";

import AuthHeader from "../AuthHeader";
import { Text } from "worklete/components/";
import AuthButton from "../AuthButton";

const ChooseRecoveryOptionForm = ({
  enterUsername,
  contactSupport,
  returnToLogin,
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <AuthHeader
        superHeader={t("AccountRecoveryOptionSHeader")}
        header={t("AccountRecoveryOptionsHeader")}
      />

      <StepButtonGroup>
        <AuthButton
          onTimeout={enterUsername}
          text={t("AccountRecoveryOptionsUsername")}
        />
        <AuthButton
          onTimeout={contactSupport}
          text={t("AccountRecoveryOptionsNoUsername")}
        />
      </StepButtonGroup>
      <div style={{ textAlign: "center", paddingTop: 40 }}>
        <Text.Link onClick={returnToLogin}>{t("ReturnToLogin")}</Text.Link>
      </div>
    </Fragment>
  );
};

export default ChooseRecoveryOptionForm;
