import React from "react";
import { Redirect } from "worklete/router";
import { useWrite, useAuthApi, useSession } from "worklete/hooks";
import Message from "Sections/WorkleteAdmin/components/Message/Message";

const SignInMasqueradeController = ({ user_id, token }) => {
  const { signed_in } = useSession();
  const { signInWithMasqueradeToken } = useAuthApi();
  const [signIn, signing_in, response] = useWrite(
    () => signInWithMasqueradeToken({ token, user_id }),
    [token, user_id],
  );

  if (response) {
    return <Redirect to="/training" />;
  } else if (signed_in) {
    return (
      <Message
        icon="error"
        message={`Cannot masquerade.
          You are currently signed in.
          Open the URL in an incognito window.`}
        actionTo={window.location.pathname}
        actionLabel="Masquerade Link"
      />
    );
  } else if (signing_in) {
    return <Message message={`Signing in...`} />;
  } else {
    return (
      <Message
        message="You are about to start masquerading"
        actionLabel="Start Masquerading"
        onAction={signIn}
      />
    );
  }
};

export default SignInMasqueradeController;
