import _ from "lodash";

window._ = _;

const worklete = (Object && Object.create && Object.create(null)) || {};

window.worklete = worklete;

const addToWorkleteGlobal = (name, value) => (worklete[name] = value);

export { addToWorkleteGlobal };
