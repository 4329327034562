import _ from "lodash";
import { useState, useEffect, useMemo } from "react";
import { makeApi } from "../makeApi/makeApi";
import routes, { api_prefix } from "./organization_api_routes";
import { loadOrganizationId } from "App/Auth/authStorage";
import useSaveOrg from "./useSaveOrg";
import parseMembership from "./helpers/parseMembership";

const initial_oid = loadOrganizationId();

const useCurrentOrgManagement = (...args) => {
  const [session = {}, orgs = [], user = {}, route_oid] = args;

  const route_org = useMemo(() => {
    // check for a valid org to switch to
    return _.find(orgs, { id: route_oid }) || {};
  }, [orgs, route_oid]);

  const valid_route_oid = route_org.id;

  const headers = session.__headers || {};

  const [oid, setOid] = useState(initial_oid);

  let organization = useMemo(() => {
    return _.find(orgs, { id: oid }) || {};
  }, [orgs, oid]);

  const active_oid = organization.id;

  const membership = useMemo(() => {
    return parseMembership(session, user, active_oid);
  }, [session, user, active_oid]);

  const default_oid = _.size(orgs) === 1 && orgs[0].id;

  useEffect(() => {
    if (valid_route_oid && valid_route_oid !== active_oid) {
      setOid(valid_route_oid);
    } else if (!active_oid && default_oid) {
      setOid(default_oid);
    }
  }, [active_oid, default_oid, valid_route_oid]);

  const token = headers["access-token"];
  const { uid, client } = headers;

  useSaveOrg(active_oid, token);

  const organizationApi = useMemo(() => {
    if (!active_oid) return {};
    const default_args = { organization_id: active_oid };
    return makeApi({ headers, api_prefix, routes, default_args });
  }, [active_oid, token, uid, client]); // eslint-disable-line

  return [organization, organizationApi, setOid, membership];
};

export default useCurrentOrgManagement;
