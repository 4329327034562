import React, { useContext } from "react";

const SetSettingContext = React.createContext(() => {});

const SettingsContext = React.createContext({});

const SettingsProvider = ({ settings = {}, setSetting, children }) => {
  return (
    <SettingsContext.Provider value={settings}>
      <SetSettingContext.Provider value={setSetting}>
        {typeof children === "function" ? children(settings) : children}
      </SetSettingContext.Provider>
    </SettingsContext.Provider>
  );
};

const useSetSetting = () => useContext(SetSettingContext);

const useSettings = () => useContext(SettingsContext);

const useSetting = (key) => {
  const setting = useSettings()[key] || {};
  return setting.value;
};

export { SettingsProvider, useSetSetting, useSettings, useSetting };
