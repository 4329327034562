import React from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";

const WorkleteLogo = (props) => {
  const { width, style, ...rest } = props;
  const styles = { width: props.width, ...props.style };
  return <Logo {...rest} style={styles} />;
};

export default WorkleteLogo;
