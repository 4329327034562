import { createMuiTheme } from "@material-ui/core/styles";
import colors from "./theming/colors";

const {
  teal_or_no_deal,
  dark_teal,
  medium_teal,
  teal_accent,
  portland_rain,
  dark_blue,
  medium_blue,
  blue_highlight,
  blue_accent,
  light_gray,
  medium_gray,
  sf_fog,
  safety_sand,
  sunshine_yellow,
} = colors;

const theme = createMuiTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      main: portland_rain,
      accent: blue_accent,
      highlight: blue_highlight,
      medium: medium_blue,
      dark: dark_blue,
      contrastText: "#fff",
    },
    secondary: {
      main: teal_or_no_deal,
      accent: teal_accent,
      medium: medium_teal,
      dark: dark_teal,
      contrastText: "#fff",
    },
    error: {
      light: sunshine_yellow,
      main: safety_sand,
    },
    text: {
      light: light_gray,
      medium: medium_gray,
      dark: sf_fog,
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Gibson Regular",
  },
});

export default theme;
