import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

const { number } = PropTypes;
const propTypes = { gap: number };
const defaultProps = { gap: 4 };

const Divider = ({ height }) => <div style={{ height }} colSpan={100} />;

const Stack = ({ children, gap }) => {
  const list = _.compact(React.Children.toArray(children));
  const count = _.size(list);
  const items = [];
  _.each(list, (item, index) => {
    items.push(item);
    if (index < count - 1)
      items.push(<Divider height={gap} key={"divider" + index} />);
  });
  return <React.Fragment>{items}</React.Fragment>;
};

Stack.propTypes = propTypes;
Stack.defaultProps = defaultProps;

export default Stack;
