import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router";
import AppLayout from "../../AppLayout/AppLayout";
import { People, Training, CoursePlayer, Scoreboard } from "Sections/Sections";
import { Placeholder, WaitToShow } from "worklete/components";

const placeholder = (
  <WaitToShow wait={250}>
    <Placeholder loading message="Loading Application..." />
  </WaitToShow>
);

const OrgRoutes = () => {
  return (
    <Switch>
      {/* Course Player is special because it doesn't have the app layout (it takes full screen)*/}
      <Route
        path="/training/course/:course_id"
        render={({ match }) => {
          const course_id = match.params.course_id;
          return <CoursePlayer course_id={course_id} />;
        }}
      />

      <Route
        path="/training/course-preview/:course_id"
        render={({ match }) => {
          const course_id = match.params.course_id;
          return <CoursePlayer course_id={course_id} preview />;
        }}
      />

      <Route>
        <AppLayout>
          <Suspense fallback={placeholder}>
            <Switch>
              <Route path="/people">
                <People />
              </Route>

              <Route path="/training">
                <Training />
              </Route>

              <Route path="/scoreboard">
                <Scoreboard />
              </Route>

              <Redirect to="/training" />
            </Switch>
          </Suspense>
        </AppLayout>
      </Route>
    </Switch>
  );
};

export default OrgRoutes;
