import _ from "lodash";

const focusable_query =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const activeEl = () => document.activeElement;

const getIsFocusable = (el) => el.matches(focusable_query);

const getFocusable = (el) => {
  return (el && el.querySelectorAll(focusable_query)) || [];
};

const hasFocusInside = (el) => {
  return el && (el === activeEl() || el.contains(activeEl()));
};

const getCurrentIndex = () => _.indexOf(getFocusable(), activeEl());

const increment = (el, step, wrap) => {
  const list = getFocusable(el);
  const current_index = getCurrentIndex();
  const next_index = current_index + step;
  return wrap && next_index < 0
    ? list[next_index + list.length]
    : wrap && next_index > list.length - 1
    ? list[next_index - list.length]
    : list[next_index];
};

const focus = (el) => el && el.focus && el.focus();

const focusFirst = (el) => focus(_.first(getFocusable(el)));

const focusLast = (el) => focus(_.last(getFocusable(el)));

const focusNext = (el) => focus(increment(el, 1));

const focusPrevious = (el) => focus(increment(el, -1));

const focusNextWithWrap = (el) => focus(increment(el, 1, true));

const focusPreviousWithWrap = (el) => focus(increment(el, -1, true));

export {
  focus,
  focusFirst,
  focusLast,
  focusNext,
  focusPrevious,
  focusNextWithWrap,
  focusPreviousWithWrap,
  getIsFocusable,
  hasFocusInside,
};
