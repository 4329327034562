import React from "react";
import { NavLink } from "worklete/router";
import classNames from "classnames";
import "./NavigationTab.scss";

const NavigationTab = ({ compact, ...rest }) => (
  <NavLink
    className={classNames("navigation-tab", { "is-compact": compact })}
    {...rest}
  />
);

export default NavigationTab;
