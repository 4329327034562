import _ from "lodash";
import React from "react";
import Icon from "@material-ui/core/Icon";
import "./Message.scss";
import AdminButton from "../AdminButton/AdminButton";
import AdminLink from "../AdminLink/AdminLink";

const icons = {
  flag: "outlined_flag",
  error: "error_outline",
};

const AdminMessage = (props) => {
  const { icon, message, action, actionLabel, onAction, actionTo } = props;

  const displayAction =
    (actionLabel && actionTo && (
      <AdminLink className="admin-message--action" to={actionTo}>
        {actionLabel}
      </AdminLink>
    )) ||
    (onAction && (
      <AdminButton primary onClick={onAction}>
        {actionLabel}
      </AdminButton>
    )) ||
    action;

  return (
    <div className="admin-message">
      <span className="admin-message--content">
        {icon && (
          <span className="admin-message--icon-area">
            {_.isString(icon) ? (
              <Icon style={{ fontSize: 56, color: "#CCC" }}>
                {icons[icon] || icon}
              </Icon>
            ) : (
              icon
            )}
          </span>
        )}

        {message && (
          <span className="admin-message--message">
            {_.isString(message)
              ? _.compact(message.split("\n").map(_.trim)).join("\n")
              : message}
          </span>
        )}

        {displayAction && (
          <div className="admin-message--action-area">{displayAction}</div>
        )}
      </span>
    </div>
  );
};

export default AdminMessage;
