import React from "react";
import { withStyles } from "@material-ui/styles";

import { Text } from "worklete/components";

const styles = (theme) => ({
  badge: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    display: "inline-block",
    verticalAlign: "top",
    borderRadius: "4px",
    padding: "1px 10px",
    margin: "4px 0 8px",
    fontSize: "12px",
    fontWeight: 700,
    textAlign: "center",
    textTransform: "none",
    letterSpacing: "0.1em",
    opacity: 0.8,
  },
});

const OrgAdminBadge = ({ classes, children, ...rest }) => {
  return (
    <Text.Body className={classes.badge} {...rest}>
      {children}
    </Text.Body>
  );
};

export default withStyles(styles)(OrgAdminBadge);
