import { useState, useLayoutEffect } from "react";

const useDebounceValue = (value, wait = 300) => {
  const [debounced_value, setDebouncedValue] = useState(value);

  useLayoutEffect(() => {
    const onTimeout = () => setDebouncedValue(value);
    const id = setTimeout(onTimeout, wait);
    return () => clearTimeout(id);
  }, [value]); // eslint-disable-line

  return debounced_value;
};

export default useDebounceValue;
