import _ from "lodash";
import { i18n } from "./i18n-config";
import { formatForDynamicLoad } from "./formatTranslationData";
import { initial_settings } from "App/System/settings/settingsHelpers";
import common from "./common";

const { localization_development_mode } = initial_settings || {};

const language_codes = ["en", "es"];

const workete_translations = { common };

const addTranslations = (namespace, translations) => {
  workete_translations[namespace] = translations;

  const data = formatForDynamicLoad(translations);
  _.each(language_codes, (lang) => {
    const lang_data = data[lang];
    if (!_.isEmpty(lang_data)) {
      i18n.addResources(lang, namespace, lang_data);
      if (localization_development_mode) {
        console.log(`Loading '${lang}' data for ${namespace}`);
      }
    }
  });
};

export { workete_translations, language_codes };

export default addTranslations;
