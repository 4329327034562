import _ from "lodash";

const languages = [
  { code: "en", displayName: "English" },
  { code: "es", displayName: "Español" },
];

const getDisplayName = (code) => {
  return (_.find(languages, { code }) || {}).displayName || "Unknown Language";
};

export { languages, getDisplayName };
