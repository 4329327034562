import React from "react";
import { useTranslation } from "worklete/hooks";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Text } from "worklete/components/";
import { useWindowSize } from "worklete/hooks";
import colors from "config/theming/colors";

import AuthButton from "../../AuthButton";
import AuthTextField from "../../AuthTextField";

import "./IndividualSignUpSteps.i18n";

const ProfileForm = ({ values, setValues, errors, canSubmit, onSubmit }) => {
  const { t } = useTranslation("IndividualSignUpSteps");
  const { width } = useWindowSize();

  return (
    <React.Fragment>
      <AuthTextField
        id="username"
        label={t("UsernameVisible")}
        value={values.username}
        onChange={(username) => setValues({ username })}
        error={values.username ? errors.username : null}
        topInput
        required
      />
      <AuthTextField
        id="password"
        label={t("PasswordMinimum")}
        type="password"
        value={values.password}
        onChange={(password) => setValues({ password })}
        error={values.password ? errors.password : null}
        required
      />
      <AuthTextField
        id="email"
        label={t("Email")}
        value={values.email}
        onChange={(email) => setValues({ email })}
        error={values.email ? errors.email : null}
      />
      <FormControlLabel
        control={
          <Checkbox
            id="confirmation"
            color="secondary"
            checked={values.confirmation}
            onChange={(e) => setValues({ confirmation: e.target.checked })}
            disableRipple
            style={{ color: colors.teal_or_no_deal }}
          />
        }
        label={
          <Text.Body
            style={{ padding: "25px 0px", fontSize: width <= 600 && "12px" }}
          >
            {t("IAgreeToThe")}
            <Text.Link
              style={{ fontSize: width <= 600 && "12px" }}
              to="https://worklete.com/worklete-terms-of-use"
            >
              {t("TermsOfUse")}
            </Text.Link>
            <span>{t("AndHaveRead")}</span>
            <Text.Link
              style={{ fontSize: width <= 600 && "12px" }}
              to="https://worklete.com/privacy-policy"
            >
              {t("PrivacyPolicy")}
            </Text.Link>
            .
          </Text.Body>
        }
        labelPlacement="end"
      />
      <AuthButton
        style={{ marginTop: "20px" }}
        text={t("Continue")}
        id="profileFormButton"
        onInitialClick={onSubmit}
        disabled={!canSubmit}
      />
    </React.Fragment>
  );
};

export default ProfileForm;
