import React, { Suspense } from "react";
import SystemManagement from "./System/SystemManagement";
import AuthManagement from "./Auth/AuthManagement";
import ThirdPartyIntegrationManager from "./ThirdParty/ThirdPartyIntegrationManager";
import AppRoutes from "./AppRoutes";
import EnvBadge from "./EnvBadge/EnvBadge";
import { QueryParamsManager } from "./QueryParamsManager/QueryParamsManager";
import LocalizationManager from "./LocalizationManager/LocalizationManager";
import Placeholder from "worklete/components/Placeholder/Placeholder";

const loading = <Placeholder loading message="Loading..." />;

const App = () => {
  return (
    <SystemManagement>
      <QueryParamsManager>
        <AuthManagement>
          <LocalizationManager>
            <Suspense fallback={loading}>
              <AppRoutes />
              <EnvBadge />
              <ThirdPartyIntegrationManager />
            </Suspense>
          </LocalizationManager>
        </AuthManagement>
      </QueryParamsManager>
    </SystemManagement>
  );
};

export default App;
