import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import colors from "config/theming/colors";
import { Link } from "react-router-dom";
import classNames from "classnames";

const styles = {
  button: {
    fontSize: "12px",
    padding: "6px 16px",
    letterSpacing: "0.22em",
    border: "none",
    fontFamily: "Gibson SemiBold",
    textTransform: "uppercase",
    backgroundColor: colors.blue_highlight,
    color: colors.teal_or_no_deal,
    "&:hover": {
      border: "none",
      backgroundColor: colors.blue_accent,
    },
  },
};

const SecondaryButton = (props) => {
  const { classes, className, style, ...rest } = props;

  const component = props.to || props.href ? Link : undefined;

  return (
    <Button
      style={{ ...style }}
      className={classNames(classes.button, className)}
      variant="contained"
      color="secondary"
      component={component}
      {...rest}
    />
  );
};

export default withStyles(styles)(SecondaryButton);
