import _ from "lodash";

const LOCAL_ENV_OVERRIDE_KEY = "worklete_env_override";
const LOCAL_ENV_CUSTOM_ORIGIN_KEY = "worklete_custom_origin";

const getCustomOrigin = () => {
  try {
    return localStorage.getItem(LOCAL_ENV_CUSTOM_ORIGIN_KEY);
  } catch (e) {
    console.error(e);
  }
};

const PROD_SERVER_ORIGIN = "https://connection.worklete.com";
const STAGE_SERVER_ORIGIN = "https://worklete-server-stage.herokuapp.com";
const LOCAL_SERVER_ORIGIN = "http://localhost:3001";
const CUSTOM_SERVER_ORIGIN = getCustomOrigin();

const ENVS = ["PROD", "STAGE", "CUSTOM", "LOCAL"];

const PROD_URLS = [
  "master.d14pew1xcndqkk.amplifyapp.com",
  "app.worklete.dev",
  "app.worklete.com",
];

const STAGE_URLS = [
  "staging.d14pew1xcndqkk.amplifyapp.com",
  "stage-app.worklete.dev",
  "stage-app.worklete.com",
];

const LOCAL_URLS = ["localhost"];

const getUrlEnv = () => {
  try {
    const host = window.location.hostname;

    return _.includes(PROD_URLS, host)
      ? "PROD"
      : _.includes(STAGE_URLS, host) || host.includes("ngrok.io")
      ? "STAGE"
      : _.includes(LOCAL_URLS, host)
      ? "LOCAL"
      : "PROD";
  } catch (e) {
    return "PROD";
  }
};

const getBuildEnv = () => {
  return null;
};

const getLocalEnv = () => {
  try {
    if (CUSTOM_SERVER_ORIGIN) {
      return "CUSTOM";
    } else {
      const override = localStorage.getItem(LOCAL_ENV_OVERRIDE_KEY);
      return _.includes(ENVS, override) ? override : null;
    }
  } catch (e) {
    return null;
  }
};

const url_env = getUrlEnv();
const build_env = getBuildEnv();
const local_env = getLocalEnv();

const getEnv = () => {
  if (url_env === "PROD") {
    return "PROD";
  } else {
    return local_env || build_env || url_env || "STAGE";
  }
};

const env = getEnv();

const getApiOrigin = () => {
  switch (env) {
    case "PROD":
      return PROD_SERVER_ORIGIN;
    case "STAGE":
      return STAGE_SERVER_ORIGIN;
    case "CUSTOM":
      return CUSTOM_SERVER_ORIGIN;
    case "LOCAL":
      return LOCAL_SERVER_ORIGIN;
    default:
      return STAGE_SERVER_ORIGIN;
  }
};

const switchEnviornment = (env, url) => {
  localStorage.removeItem(LOCAL_ENV_CUSTOM_ORIGIN_KEY);
  localStorage.removeItem(LOCAL_ENV_OVERRIDE_KEY);

  if (env === "CUSTOM" && url && url !== PROD_SERVER_ORIGIN) {
    localStorage.setItem(LOCAL_ENV_CUSTOM_ORIGIN_KEY, url);
  } else if (env && env !== url_env) {
    localStorage.setItem(LOCAL_ENV_OVERRIDE_KEY, env);
  }
  window.location.reload(true);
};

const api_origin = getApiOrigin();

const is_development = !(url_env === "PROD" || url_env === "STAGE");

const server = {
  env: env,
  url_env: url_env,
  api_origin: api_origin,
  is_development,
  switchToProd: () => switchEnviornment("PROD"),
  switchToStage: () => switchEnviornment("STAGE"),
  switchToLocal: () => switchEnviornment("LOCAL"),
  switchToCustom: (url) => switchEnviornment("CUSTOM", url),
};

export { env, url_env, api_origin, is_development };

export default server;
