import React from "react";

import { useTranslation } from "worklete/hooks";

import ConfirmText from "./ConfirmText";
import { getDisplayName } from "config/language/language";

import "../IndividualSignUpSteps.i18n";

const ConfirmPage = ({ values, onProfileChange, onLanguageChange }) => {
  const { t } = useTranslation("IndividualSignUpSteps");

  const { username, password, email, language } = values;

  return (
    <React.Fragment>
      <ConfirmText
        label={t("UsernameVisible")}
        value={username}
        onEdit={onProfileChange}
      />
      <ConfirmText
        label={t("Password")}
        value={password}
        onEdit={onProfileChange}
        password
      />
      <ConfirmText
        label={t("Email")}
        value={email}
        onEdit={onProfileChange}
        email
      />
      <ConfirmText
        label={t("Language")}
        value={getDisplayName(language)}
        onEdit={onLanguageChange}
      />
    </React.Fragment>
  );
};

export default ConfirmPage;
