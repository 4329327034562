import { useEffect } from "react";

import { useQueryParamMethods } from "../../QueryParamsManager/QueryParamsManager";

const useRouteOidRemoval = (active_org = {}, route_oid) => {
  const active_oid = active_org.id;

  const { removeRouteOid } = useQueryParamMethods();

  useEffect(() => {
    if (route_oid && route_oid === active_oid) {
      removeRouteOid();
    }
  }, [active_oid, route_oid, removeRouteOid]);
};

export default useRouteOidRemoval;
