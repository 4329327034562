import React from "react";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  subHeader: {
    fontWeight: "900",
    textTransform: "uppercase",
    color: theme.palette.text.dark,
    fontSize: "18px",
    letterSpacing: "2.5px",
  },
});

const SubHeaderText = ({ classes, className, children, style }) => (
  <Typography
    className={classNames(classes.subHeader, className)}
    style={style}
    variant="body2"
  >
    {children}
  </Typography>
);

export default withStyles(styles)(SubHeaderText);
