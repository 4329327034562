import React from "react";
import { withStyles } from "@material-ui/styles";

import { useWindowSize } from "worklete/hooks";
import { StepButton } from "worklete/components";

const styles = (theme) => ({
  authButton: {
    marginTop: "20px",
    marginRight: "20px",
    fontWeight: 1000,
  },
});

const AuthButton = (props) => {
  const { classes, className, text, ...rest } = props;

  const { is_mobile } = useWindowSize();

  const authButtonStlye = {
    fontSize: is_mobile && "14px",
    maxWidth: is_mobile && "350px",
  };

  return (
    <StepButton
      style={authButtonStlye}
      className={classes.authButton}
      {...rest}
    >
      {text}
    </StepButton>
  );
};

export default withStyles(styles)(AuthButton);
