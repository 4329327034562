import React from "react";
import Slide from "@material-ui/core/Slide";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/styles";
import {
  useWindowSize,
  useClickOutside,
  useBodyScrollLock,
  useDebounceValue,
  useFocusInside,
  useTranslation,
} from "worklete/hooks";
import { Text } from "worklete/components";
import DesktopProfileContent from "./DesktopProfileContent";
import MobileProfileContent from "./MobileProfileContent";

const styles = (theme) => ({
  profileCard: {
    backgroundColor: theme.palette.primary.dark,
    textAlign: "left",
    position: "fixed",
    top: 12,
    right: 12,
    maxHeight: "90%",
    borderRadius: "4px",
    zIndex: 1000,
    overflow: "auto",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.7)",
  },
  closeIcon: {
    color: theme.palette.secondary.main,
    marginBottom: "30px",
  },
  iconText: {
    color: theme.palette.secondary.main,
    letterSpacing: "0.22em",
    fontFamily: "Gibson SemiBold",
    textTransform: "uppercase",
    paddingLeft: "5px",
    fontSize: "10px",
  },
});

const ProfileCard = ({ classes, is_open, onClose }) => {
  const { t } = useTranslation("ProfileCard");
  const { width } = useWindowSize();
  const compact = width < 800;
  const superCompact = width < 450;
  const enbable_click_outside = useDebounceValue(is_open, 0);
  const ref = useClickOutside(enbable_click_outside ? onClose : null);
  const contentRef = useFocusInside(is_open);

  useBodyScrollLock(is_open);

  return (
    <span ref={ref}>
      <Slide direction="down" timeout={450} mountOnEnter in={is_open}>
        <div
          ref={contentRef}
          style={{
            width: compact ? (superCompact ? "90%" : "350px") : "450px",
            padding: compact ? "20px 0px" : "20px",
          }}
          className={classes.profileCard}
        >
          <IconButton
            style={{ marginLeft: "4px" }}
            onClick={onClose}
            className={classes.closeIcon}
          >
            <Icon>expand_less</Icon>
            <Text.Body className={classes.iconText}>{t("Close")}</Text.Body>
          </IconButton>
          {compact ? <MobileProfileContent /> : <DesktopProfileContent />}
        </div>
      </Slide>
    </span>
  );
};

export default withStyles(styles)(ProfileCard);
