import React from "react";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  header: {
    color: theme.palette.text.light,
    textTransform: "uppercase",
    fontSize: "48px",
    paddingRight: "10px", //see if we can get rid of this
    fontFamily: "Interstate Regular Compressed",
    letterSpacing: "2px",
    lineHeight: "1.15",
  },
});

const HeaderText = ({ classes, className, style, children }) => {
  return (
    <Typography
      variant="body2"
      className={classNames(classes.header, className)}
      style={style}
      varient="body2"
    >
      {children}
    </Typography>
  );
};

export default withStyles(styles)(HeaderText);
