import { useEffect, useRef } from "react";

const usePrevious = (value) => {
  const ref = useRef(); // should be empty on first render

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
