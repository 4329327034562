import _ from "lodash";
import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";

const MOBILE_WIDTH = 600;
const TABLET_WIDTH = 960;

const getMode = ({ width, height }) => {
  if (width / height > 1.2 && height <= 450) {
    return "MOBILE_LANDSCAPE";
  } else if (width <= MOBILE_WIDTH) {
    return "MOBILE_PORTRAIT";
  } else if (width <= TABLET_WIDTH) {
    return "TABLET";
  } else {
    return "DESKTOP";
  }
};

const getDims = () => {
  return { width: window.innerWidth, height: window.innerHeight };
};

const WindowContext = createContext(getDims());

const ResponsiveManager = ({ children }) => {
  const [dimensions, setDimensions] = useState(getDims());

  const onChange = useMemo(() => {
    const handleChange = () =>
      setDimensions((oldDimnensions) => {
        const newDimensions = getDims();
        const { width: newWidth, height: newHeight } = newDimensions;
        const { width: oldWidth, height: oldHeight } = oldDimnensions;
        const changed = newWidth !== oldWidth || newHeight !== oldHeight;
        return changed ? newDimensions : oldDimnensions;
      });
    return _.throttle(handleChange, 100, { trailing: true });
  }, []);

  useEffect(() => {
    const interval = setInterval(onChange, 500);
    return () => {
      clearInterval(interval);
      onChange.cancel();
    };
  }, [onChange]);

  useEffect(() => {
    window.addEventListener("resize", onChange);
    return () => {
      window.removeEventListener("resize", onChange);
      onChange.cancel();
    };
  }, [onChange]);

  const { width, height } = dimensions;

  const value = useMemo(() => {
    const mode = getMode({ width, height });
    const is_desktop = mode === "DESKTOP";
    const is_tablet = mode === "TABLET";
    const is_mobile_landscape = mode === "MOBILE_LANDSCAPE";
    const is_mobile_portrait = mode === "MOBILE_PORTRAIT";
    const is_mobile = is_mobile_landscape || is_mobile_portrait;
    return {
      height,
      width,
      mode,
      is_mobile_landscape,
      is_mobile_portrait,
      is_mobile,
      is_tablet,
      is_desktop,
    };
  }, [width, height]);

  return (
    <WindowContext.Provider value={value}>{children}</WindowContext.Provider>
  );
};

const useWindowSize = () => useContext(WindowContext);

export { useWindowSize, ResponsiveManager };
