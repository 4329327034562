import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { useWindowSize } from "worklete/hooks";
import { Text } from "worklete/components";

const styles = (theme) => ({
  headerCell: {
    borderBottom: "none",
  },
  headerText: {
    color: theme.palette.text.medium,
    fontWeight: 600,
    fontSize: "14px",
  },
});

const HeaderCell = ({
  classes,
  collapsible,
  paddedText,
  headerStyle,
  label,
}) => {
  const { is_mobile } = useWindowSize();

  return (
    <TableCell
      className={classes.headerCell}
      style={
        collapsible
          ? { display: is_mobile && "none", ...headerStyle }
          : headerStyle
      }
    >
      <Text.Body
        style={{ paddingLeft: paddedText && "25px" }}
        className={classes.headerText}
      >
        {label}
      </Text.Body>
    </TableCell>
  );
};

export default withStyles(styles)(HeaderCell);
