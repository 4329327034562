import _ from "lodash";
import React, { createContext, useContext, useMemo } from "react";

import { useSession } from "worklete/hooks";
import { makeApi } from "../makeApi/makeApi";
import routes, { api_prefix } from "./admin_api_routes";

const AdminApiContext = createContext({});

AdminApiContext.displayName = "AdminApiContext";

const AdminApiManager = ({ children }) => {
  const { __headers: headers } = useSession();

  const dependencies = _.values(headers);

  const adminApi = useMemo(
    () => makeApi({ headers, api_prefix, routes }),
    dependencies, // eslint-disable-line
  );

  return (
    <AdminApiContext.Provider value={adminApi}>
      {children}
    </AdminApiContext.Provider>
  );
};

const useAdminApi = () => useContext(AdminApiContext);

export { useAdminApi };

export default AdminApiManager;
