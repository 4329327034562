import { addToWorkleteGlobal } from "config/worklete-global";
import React, { useEffect } from "react";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY;
const releaseStage = process.env.REACT_APP_BUGSNAG_RELEASE_STAGE || "unknown";

const initializeBugsnag = () => {
  if (apiKey) {
    const realBugsnagClient = bugsnag({
      apiKey,
      appType: "client",
      releaseStage,
    });

    realBugsnagClient.use(bugsnagReact, React);

    return {
      notify: (...args) => {
        realBugsnagClient.notify(...args);
      },
      setUser: (bugsnag_user) => {
        realBugsnagClient.user = bugsnag_user;
      },
      realBugsnagClient,
    };
  } else {
    window.onunhandledrejection = (event) => {
      console.warn("[Bugsnag Local Unhandled Promise Rejection]", event.reason);
    };
    return {
      notify: (...args) => {
        console.warn("[Bugsnag Local Notify]", ...args);
      },
      setUser: (bugsnag_user) => {
        console.warn("[Bugsnag Local Set User]", bugsnag_user);
      },
    };
  }
};

const bugsnagClient = initializeBugsnag();

addToWorkleteGlobal("bugsnag", bugsnagClient);

const useSetBugsnagUser = (user, headers) => {
  const {
    id: user_id,
    name,
    email,
    username,
    language_code,
    worklete_employee,
  } = user || {};

  const { uid: session_uid, client: session_client } = headers || {};

  const user_info = user_id && {
    user_id,
    name,
    email,
    username,
    language_code,
    worklete_employee,
  };
  const bugsnag_user = { ...user_info, session_uid, session_client };

  useEffect(() => {
    bugsnagClient.setUser(bugsnag_user);
    return () => bugsnagClient.setUser({});
  }, [user_id, session_uid]); // eslint-disable-line react-hooks/exhaustive-deps
};

export { useSetBugsnagUser };

export default bugsnagClient;
