import _ from "lodash";
import { api_origin } from "App/System/server/server";

const HEADERS = ["access-token", "uid", "client"];

const default_headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const apiCall = ({ url, body, headers, ...options }) => {
  if (body) {
    options.body = JSON.stringify(body);
  }
  options.headers = { ...default_headers, ...headers };

  return new Promise((resolve, reject) => {
    fetch(api_origin + url, options)
      .then((resp) => {
        const { ok, url, status, statusText } = resp;
        const headers = {};
        if (resp.headers.forEach) {
          resp.headers.forEach((val, key) => (headers[key] = val));
        } else {
          _.each(resp.headers, (val, key) => (headers[key] = val));
        }
        if (ok) {
          if (status === 204) {
            resolve({ status, headers, data: {}, url });
          } else {
            resp.json().then((data) => {
              resolve({ status, headers, data, url });
            });
          }
        } else {
          resp
            .json()
            .then((error) => {
              const message =
                (error && error.message) ||
                statusText ||
                "Unknown Network Error";
              reject({ status, headers, message, error, url });
            })
            .catch((error) => {
              const message = statusText || "Unknown Network Error";
              reject({ status, headers, message, error, url });
            });
        }
      })
      .catch((error) =>
        reject({
          status: 0,
          headers: {},
          message: (error && error.message) || "Unknown Error",
          error,
          url,
        }),
      );
  });
};

const handleSessionResponse = (resp) => {
  const headers = _.pick(resp.headers, HEADERS);
  const { memberships, organizations, ...user } = resp.data.data;
  const received_at = Date.now();

  return { headers, received_at, user, organizations, memberships };
};

const refreshSession = (headers) => {
  return apiCall({
    url: "/api/v1/session",
    method: "GET",
    headers: headers,
  }).then(handleSessionResponse);
};

const signUp = ({
  email,
  password,
  username,
  first_name,
  last_name,
  invite_code,
  language,
}) => {
  return apiCall({
    url: "/api/v1/auth",
    method: "POST",
    body: {
      email,
      password,
      username,
      first_name,
      last_name,
      invite_code,
      language,
      provider: "username",
    },
  }).then(handleSessionResponse);
};

const signIn = ({ username, password }) => {
  return apiCall({
    url: "/api/v1/auth/sign_in",
    method: "POST",
    body: { username, password, provider: "username" },
  }).then(handleSessionResponse);
};

const signOut = (headers) => {
  return apiCall({
    url: "/api/v1/session",
    method: "DELETE",
    headers: headers,
  });
};

const requestPasswordReset = (username) => {
  return apiCall({
    url: `/api/v1/auth/password_reset`,
    body: { username },
    method: "POST",
  });
};

const checkUsername = (username) => {
  return _.size(username) < 4
    ? Promise.resolve(false)
    : apiCall({
        url: `/api/v1/auth/username/${username}`,
        method: "GET",
      }).then(({ data }) => data.data.available);
};

const changePassword = (headers, pass1, pass2) => {
  return apiCall({
    url: "/api/v1/auth/password",
    method: "PUT",
    headers,
    body: { password: pass1, password_confirmation: pass2 },
  });
};

const refreshCognitoSession = (headers) => {
  return apiCall({
    url: "/api/v1/cognito/session",
    method: "GET",
    headers,
  }).then(({ data }) => data);
};

const changeEmail = (headers, user_id, email) => {
  return apiCall({
    url: `/api/v1/users/${user_id}`,
    method: "PATCH",
    headers,
    body: { email },
  });
};

const getInvitation = (invite_code) => {
  return _.size(invite_code) !== 6
    ? Promise.reject({ error: "Invitation Code should be 6 digits" })
    : apiCall({
        method: "GET",
        url: `/api/v1/invitations/${invite_code}`,
      }).then(({ data }) => data);
};

const generateMasqueradeToken = (headers, user_id) => {
  return apiCall({
    url: `/api/v1/users/${user_id}/link`,
    method: "POST",
    headers: headers,
  }).then((resp) => {
    return {
      token: resp.data.data["token"],
      user_id: resp.data.data["id"],
      provider: resp.data.data["provider"],
    };
  });
};

const signInWithMasqueradeToken = (params) => {
  return apiCall({
    url: "/api/v1/auth/sign_in",
    method: "POST",
    body: {
      "access-token": params.token,
      id: params.user_id,
      provider: "masquerade",
    },
  }).then(handleSessionResponse);
};

const fetchOrganizations = (headers) => {
  return apiCall({
    headers,
    url: "/api/v1/organizations",
    method: "GET",
  }).then(({ data }) => data);
};

export {
  refreshSession,
  signUp,
  signIn,
  signOut,
  checkUsername,
  changePassword,
  requestPasswordReset,
  changeEmail,
  getInvitation,
  generateMasqueradeToken,
  signInWithMasqueradeToken,
  fetchOrganizations,
  refreshCognitoSession,
};
