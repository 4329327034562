import _ from "lodash";

const getUsernameErrors = (u, available, loading) => {
  const l = _.size(u);
  return l < 4
    ? "Username must be at least 4 characters"
    : l > 20
    ? "Username must be 20 characters or less"
    : !(u || "").match(/^[a-zA-Z0-9._]+$/)
    ? "No special characters besides underscore or period"
    : !u
    ? "Username is required"
    : loading
    ? "Checking username..."
    : !available
    ? "Username is not available"
    : null;
};

const getPasswordErrors = (pw = "") => {
  const l = _.size(pw);
  return l < 6 ? "Password must be at least 6 characters" : null;
};

const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

const getEmailErrors = (email = "") => {
  return email && !email.match(email_regex)
    ? "Please enter a valid email address"
    : null;
};

const getConfirmationErrors = (confirmation = false) =>
  !confirmation && "Please accepct our terms and conditions";

export {
  getUsernameErrors,
  getPasswordErrors,
  getEmailErrors,
  getConfirmationErrors,
};
