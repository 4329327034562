import { useUser } from "../Auth/session/SessionProviders";
import { useCurrentOrg } from "../Auth/currentOrg/CurrentOrgProviders";

import useGoogleAnalytics from "./GoogleAnalytics/useGoogleAnalytics";
import useZendesk from "./Zendesk/useZendesk";

const ThirdPartyIntegrationManager = ({ children }) => {
  const user = useUser();
  const organization = useCurrentOrg();

  useZendesk(user);

  useGoogleAnalytics(user, organization);

  return children || null;
};

export default ThirdPartyIntegrationManager;
