export default {
  teal_or_no_deal: "#3CDCC9",
  dark_teal: "#00AB9C",
  medium_teal: "#23C2AF",
  teal_accent: "#BFFDEE",
  portland_rain: "#021063",
  dark_blue: "#001645",
  medium_blue: "#001D58",
  blue_highlight: "#07236A",
  blue_accent: "#012D8A",
  safety_sand: "#F8FF69",
  sunshine_yellow: "#FAFF90",
  light_gray: "#F3F3F3",
  medium_gray: "#AFB0B0",
  sf_fog: "#97999B",
};
