import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import {
  useSession,
  useTranslation,
  useWrite,
  useAuthApi,
} from "worklete/hooks";
import { Text } from "worklete/components";
import { getEmailErrors } from "../helpers/formValidationHelpers";
import "./ResetPassword.i18n";

import { changeEmail } from "App/Auth/authApi";

import AuthButton from "../AuthButton";
import AuthTextField from "../AuthTextField";

const UpdateEmailForm = ({ username, email, user_id, onComplete }) => {
  const [newEmail, setNewEmail] = useState(email || "");
  const { t } = useTranslation("ResetPassword");
  const { __headers: headers } = useSession();
  const { refreshSession } = useAuthApi();

  const handleStepComplete = () => {
    if (!sending && !emailError) {
      onComplete();
    }
  };

  const [send, sending, , error, clear] = useWrite(() => {
    return (
      changeEmail(headers, user_id, newEmail)
        .then(refreshSession)
        // Defering because the useWrite hook is causing a React warning
        // Long-term solution is to track mounted in the useWrite hook
        .then(() => _.defer(onComplete))
    );
  });

  const emailError =
    getEmailErrors(newEmail) || (error && t("UpdateEmailFormEmailError"));

  const handleComplete = newEmail !== email ? send : handleStepComplete;

  useEffect(() => {
    if (error) clear();
  }, [newEmail]); // eslint-disable-line

  return (
    <Fragment>
      <Text.Label>{t("UpdateEmailFormUsernameLabel")}</Text.Label>
      <Text.SuperHeader style={{ paddingBottom: "24px" }}>
        {username}
      </Text.SuperHeader>
      <Text.Label>{t("UpdateEmailFormEmailLabel")}</Text.Label>
      <AuthTextField
        id="email"
        label={t("UpdateEmailFormAuthEmailLabel")}
        value={newEmail}
        onChange={(email) => setNewEmail(email)}
        error={newEmail && emailError}
      />
      <AuthButton
        text={t("UpdateEmailFormAuthButtonLabel")}
        disabled={!!emailError || sending}
        onTimeout={handleComplete}
      />
    </Fragment>
  );
};

export default UpdateEmailForm;
