import { i18nUseTranslation } from "../../translations";

const options = { useSuspense: false };

const t_noop = (key) => key;

const useTranslation = (namespace) => {
  const val = i18nUseTranslation(namespace || "common", options);
  val.t_noop = t_noop;
  return val;
};

export default useTranslation;
