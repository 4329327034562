import { useEffect, useCallback, useState, useDebugValue } from "react";

const useToggle = (val) => {
  const prop_value = !!val;

  const [value, setValue] = useState(prop_value);

  const onToggleOn = useCallback(() => setValue(true), []);
  const onToggleOff = useCallback(() => setValue(false), []);

  useDebugValue(value ? "On" : "Off");

  useEffect(() => {
    if (prop_value !== value) setValue(prop_value);
  }, [prop_value]); // eslint-disable-line

  return [value, onToggleOn, onToggleOff];
};

export default useToggle;
