import { withStyles } from "@material-ui/styles";
import ProfileCardContent from "./ProfileCardContent";

const desktopStyles = (theme) => ({
  tealHeaderText: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    paddingBottom: "20px",
  },
  fullNameHeader: {
    fontSize: "26px",
    paddingBottom: "8px",
    display: "inline-block",
    marginRight: "30px",
  },
  greyBodyText: {
    color: theme.palette.text.dark,
    fontSize: "16px",
  },
  sectionEnd: {
    paddingBottom: "20px",
  },
  lastSection: {
    paddingBottom: "40px",
  },
  infoBodyText: {
    fontSize: "18px",
  },
  bodyWrapper: {
    paddingLeft: "20px",
  },
  termsParagraph: {
    paddingTop: "50px",
    textAlign: "left",
  },
  paddedAnd: {
    padding: "0px 5px",
  },
  teamWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const DesktopProfileContent = withStyles(desktopStyles)(ProfileCardContent);

export default DesktopProfileContent;
