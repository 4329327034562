import addTranslations from "worklete/translations";

addTranslations("ProfileCard", {
  And: {
    en: "and",
  },
  ByCreating: {
    en: "By creating a Worklete account, you agree to our ",
  },
  Close: {
    en: "Close",
  },
  Email: {
    en: "Email",
  },
  JobTitle: {
    en: "Job Title",
  },
  Language: {
    en: "Language",
  },
  OrganizationAdmin: {
    en: "Organization Admin",
  },
  PrivacyPolicy: {
    en: "Privacy Policy",
  },
  Profile: {
    en: "Profile",
  },
  Team: {
    en: "Team",
  },
  TermsOfUse: {
    en: "Terms of Use",
  },
  Username: {
    en: "Username",
  },
  WorkleteEmployee: {
    en: "Worklete Employee",
  },
});
