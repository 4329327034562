import React from "react";
import { useWindowSize } from "worklete/hooks";
import "./SectionHeader.scss";

const SectionHeader = ({
  title,
  subtitle,
  supertitle,
  action,
  onExpand,
  paddingBottom = 32,
}) => {
  const { is_mobile } = useWindowSize();
  const side_padding = is_mobile ? 16 : 50;

  return (
    <div
      className="section-header"
      style={{
        paddingLeft: side_padding,
        paddingRight: side_padding,
        paddingBottom,
      }}
    >
      {supertitle && (
        <div className="section-header--supertitle">
          <div className="section-header--supertitle-text">{supertitle}</div>
          {action && <div className="section-header--action">{action}</div>}
        </div>
      )}
      <div className="section-header--title">
        <div className="section-header--title-text">{title}</div>
        {!supertitle && action && (
          <div className="section-header--action">{action}</div>
        )}
      </div>

      {subtitle && <div className="section-header--subtitle">{subtitle}</div>}
    </div>
  );
};

export default SectionHeader;
