const api_prefix = "/api/v1";

const routes = {
  users: {
    search: "/users/search",
    user: "/users/:user_id",
    set_password: "/worklete_admin/set_password",
    password_reset: "/worklete_admin/password_reset",
    champion_certifications: "/worklete_admin/champion_certifications",
    champion_certification: "/worklete_admin/champion_certifications/:id",
  },
  admin_users: {
    user: "/worklete_admin/users/:user_id",
  },
  organizations: {
    organization: "/organizations/:organization_id",
    organizations: "/organizations",
    teams: "/organizations/:organization_id/teams",
    saml_service_provider_metadata:
      "/worklete_admin/organizations/:organization_id/saml_service_provider_metadata",
    memberships: "/organizations/:organization_id/memberships/search",
    membership: "/memberships/:member_id",
    job_titles: "/worklete_admin/organizations/:organization_id/job_titles",
    training_categories:
      "/worklete_admin/organizations/:organization_id/training_categories",
    training_category:
      "/worklete_admin/organizations/:organization_id/training_categories/:training_category_id",
  },
  orgs: {
    organizations: "/worklete_admin/organizations",
    organization: "/worklete_admin/organizations/:organization_id",
    teams: "/worklete_admin/organizations/:organization_id/teams",
    team: "/worklete_admin/organizations/:organization_id/teams/:team_id",
    course_pools: "/worklete_admin/organizations/:organization_id/course_pools",
    userlist_url:
      "/worklete_admin/organizations/:organization_id/upload_members_list_url",
  },
  teams: {
    team: "/teams/:team_id",
    invites: "/teams/:team_id/invites",
    admins: "/teams/:team_id/admins",
    admin: "/teams/:team_id/admins/:membership_id",
    potential_admins: "/teams/:team_id/admins/potential_admins",
    course_refinements: "/worklete_admin/teams/:team_id/refinements",
    course_refinement:
      "/worklete_admin/teams/:team_id/refinements/:refinement_id",
  },
  courses: {
    courses: "/worklete_admin/courses",
    course: "/worklete_admin/courses/:course_id",
    versions: "/worklete_admin/courses/:course_id/versions",
    version: "/worklete_admin/courses/:course_id/versions/:version_id",
  },
  media_converter: {
    transcode: "/worklete_admin/media_converter",
  },
  assets: {
    uuid: "/uuid/new",
  },
};

export { api_prefix };

export default routes;
