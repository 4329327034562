import { useWindowSize } from "App/System/responsive/ResponsiveManager";

const useLayoutProperties = () => {
  const { is_mobile } = useWindowSize();
  return {
    is_mobile,
    section_gap: is_mobile ? 16 : 50,
  };
};

export default useLayoutProperties;
