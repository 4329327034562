import { useEffect } from "react";
import { saveHeaders, saveOrganizationId } from "../authStorage";

const useSaveSession = (headers = {}) => {
  const token = headers["access-token"];
  const { uid, client } = headers;

  const saveSession = () => {
    if (token) {
      saveHeaders(headers);
    } else {
      saveHeaders({});
      saveOrganizationId(null);
    }
  };

  useEffect(saveSession, [token, uid, client]);
};

export default useSaveSession;
