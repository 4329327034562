import { useRef, useEffect } from "react";

const useClickOutside = (onClickOutside, { ref: passedRef } = {}) => {
  const defaultRef = useRef();
  const ref = passedRef || defaultRef;

  useEffect(() => {
    const handleClick = ({ target } = {}) => {
      const is_inside = ref.current && ref.current.contains(target);
      if (!is_inside && onClickOutside) onClickOutside();
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [onClickOutside, ref]);

  return ref;
};

export default useClickOutside;
