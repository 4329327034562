import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { initial_settings } from "App/System/settings/settingsHelpers";

import { formatForPreload } from "./formatTranslationData";

import common from "./common";
const resources = formatForPreload("common", common);

const { localization_development_mode, language_code } = initial_settings || {};

i18n.use(initReactI18next).init({
  debug: !!localization_development_mode,
  preload: true,
  resources,
  ns: ["common"],
  defaultNS: "common",
  fallbackNS: "common",
  lng: language_code || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

window.i18n = i18n;

export { i18n, useTranslation as i18nUseTranslation };
