import _ from "lodash";
import React from "react";
import { StepButtonGroup } from "worklete/components";
import AuthButton from "../../AuthButton";
import CodeInput from "react-code-input";
import { useTranslation } from "worklete/hooks";
import { Text } from "worklete/components";
import { useWindowSize } from "worklete/hooks";
import "./IndividualSignUpSteps.i18n";

const FIELDS = 6;
const inputWidth = "" + Math.floor(80 / FIELDS) + "%";
const inputMargin = "" + Math.floor(20 / FIELDS) + "%";

const InviteCodeScreen = (props) => {
  const { t } = useTranslation("IndividualSignUpSteps");
  const { width } = useWindowSize();
  const { invite_code, invite_error, onInviteCodeChange, onSubmit } = props;

  invite_error && console.log("invite error: ", invite_error);

  return (
    <StepButtonGroup>
      <CodeInput
        type="text"
        fields={6}
        value={invite_code}
        onChange={(code) => onInviteCodeChange(code.toUpperCase())}
        inputStyle={{
          width: inputWidth,
          marginRight: inputMargin,
          borderRadius: "3px",
          fontSize: width <= 600 ? "20px" : "32px",
          height: width <= 600 ? "48px" : "64px",
          textAlign: "center",
          textTransform: "uppercase",
          backgroundColor: "transparent",
          color: "#FFF",
          border: "1px solid #FFF",
        }}
      />

      {invite_error && (
        <Text.Error style={{ paddingTop: 12 }}>{invite_error}</Text.Error>
      )}

      <AuthButton
        disabled={_.size(invite_code) !== 6}
        onInitialClick={onSubmit}
        text="Submit"
      />

      <div style={{ textAlign: "center", paddingTop: 40 }}>
        <Text.Link to="/signin">{t("AlreadyHave")}</Text.Link>
      </div>
    </StepButtonGroup>
  );
};

export default InviteCodeScreen;
