import React from "react";
import AppHeader from "./AppHeader/AppHeader";
import AppNavigation from "./AppNavigation/AppNavigation";
import "./AppLayout.scss";

const AppLayout = ({ children, hideNavigation }) => {
  return (
    <div className="app-layout">
      <AppHeader />
      <div className="app-layout--page-wrapper">
        <div className="app-layout--page">
          {!hideNavigation && <AppNavigation />}
          <div className="app-layout--content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
