import React from "react";
import ErrorText from "../Text/ErrorText";
import { useWindowSize } from "worklete/hooks";
import { errorMessage } from "worklete/format";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StepButton } from "worklete/components";
import PropTypes from "prop-types";
import "./Placeholder.scss";

const { any, bool, string, func } = PropTypes;

const propTypes = {
  loading: bool,
  message: any,
  error: any,
  actionTo: string,
  onAction: func,
  actionLabel: any,
};

const Placeholder = ({ loading, message, error, ...rest }) => {
  const { actionTo, onAction, actionLabel } = rest;
  const { is_mobile } = useWindowSize();
  const error_message = errorMessage(error);

  return (
    <div className="placeholder" style={{ padding: is_mobile ? 16 : 40 }}>
      {loading && (
        <div className="placeholder--spinner">
          <CircularProgress style={{ fontSize: 24 }} color="secondary" />
        </div>
      )}

      {message && <div className="placeholder--message">{message}</div>}

      {!!error_message && <ErrorText>{error_message}</ErrorText>}

      {(actionTo || onAction) && actionLabel && (
        <div className="placeholder--actions">
          <StepButton to={actionTo} onInitialClick={onAction}>
            {actionLabel}
          </StepButton>
        </div>
      )}
    </div>
  );
};

Placeholder.propTypes = propTypes;

export default Placeholder;
