import _ from "lodash";
import { useRef } from "react";
import { useRect as reachUseRect } from "@reach/rect";

const block_defaults = { width: Infinity, height: 0 };

const inline_defaults = { width: 0, height: 0 };

const useRect = ({ inline, ref: passedRef, defaults } = {}) => {
  const defaultRef = useRef();

  const ref = passedRef || defaultRef;

  const reachRect = reachUseRect(ref);

  const rect =
    reachRect || defaults || (inline ? inline_defaults : block_defaults);

  return [_.extend({}, rect), ref];
};

export default useRect;
