import { useEffect } from "react";
import { updateZendesk, clearZendesk } from "./zendeskHelpers";

const useZendesk = (user = {}) => {
  const { name, email, id } = user;

  const handleZendesk = () => {
    if (id) updateZendesk(name, email);

    return () => id && clearZendesk();
  };

  useEffect(handleZendesk, [id]);
};

export default useZendesk;
