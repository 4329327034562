import React, { useState } from "react";
import { useTranslation } from "worklete/hooks";
import { withRouter } from "react-router-dom";
import { useWrite, useAuthApi } from "worklete/hooks";
import testSignIn from "./test_sign_in.jpg";
import AuthButton from "../AuthButton";
import AuthLayout from "../AuthLayout";
import AuthHeader from "../AuthHeader";
import AuthTextField from "../AuthTextField";

import { Text } from "worklete/components";
import "./SignIn.i18n";

const getSigninErrorMessage = (error) => {
  if (!error) return;
  try {
    return error.error.errors[0];
  } catch (e) {
    return "Unknown problem signing in";
  }
};

const SignInController = ({ history }) => {
  const AuthApi = useAuthApi();
  const { t } = useTranslation("SignIn");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [touched, setTouched] = useState(false);

  const [send, , , error] = useWrite(() => {
    return AuthApi.signIn({ username, password });
  });

  const signIn = () => send().catch(() => {});

  const disabled = !username || !password;

  const username_error = touched && !username && t("ErrorUsername");
  const password_error = touched && !password && t("ErrorPassword");

  const onEnter = () => {
    if (disabled) {
      setTouched(true);
    } else {
      signIn();
    }
  };

  return (
    <AuthLayout backgroundImage={testSignIn} mediumLong>
      <AuthHeader
        superHeader={t("AuthSuperHeaderSignIn")}
        header={t("AuthHeaderSignIn")}
      />
      <AuthTextField
        id="username"
        label={t("AuthLabelUsername")}
        value={username}
        onChange={setUsername}
        error={username_error}
        onEnter={onEnter}
        topInput
        required
      />

      <AuthTextField
        id="password"
        label={t("AuthLabelPassword")}
        type="password"
        value={password}
        onChange={setPassword}
        error={password_error}
        onEnter={onEnter}
        required
      />

      <div onClick={() => setTouched(true)}>
        <AuthButton
          style={{ width: "100%" }}
          text={t("AuthSignIn")}
          id="signInButton"
          onInitialClick={signIn}
          disabled={disabled}
        />
      </div>

      {error && (
        <div style={{ padding: "20px 0" }}>
          <Text.Error>{getSigninErrorMessage(error)}</Text.Error>
        </div>
      )}
      <div style={{ textAlign: "center", paddingTop: 40 }}>
        <Text.Link to="/recover_account">{t("CantAccessMyAccount")}</Text.Link>
      </div>
      <div style={{ textAlign: "center", paddingTop: 40 }}>
        <Text.Link to="/signup">{t("HaveInviteCode")}</Text.Link>
      </div>
    </AuthLayout>
  );
};

export default withRouter(SignInController);
