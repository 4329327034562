import React from "react";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HeaderCell from "./HeaderCell";
import SearchTableBody from "./SearchTableBody";
import SearchTableError from "./SearchTableError";

const SearchTable = ({ classes, columns, data, errorMessage }) => {
  const dataPresent = data && data.length;

  return (
    <React.Fragment>
      <Table style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => {
              const headerProps = _.pick(column, [
                "collapsible",
                "headerStyle",
                "label",
              ]);
              return (
                <HeaderCell
                  {...headerProps}
                  paddedText={index === 0}
                  key={index}
                />
              );
            })}
          </TableRow>
        </TableHead>
        {dataPresent ? <SearchTableBody columns={columns} data={data} /> : null}
      </Table>
      {!dataPresent && errorMessage && (
        <SearchTableError errorMessage={errorMessage} />
      )}
    </React.Fragment>
  );
};

export default SearchTable;
