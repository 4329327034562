import {
  useReducer,
  useLayoutEffect,
  useEffect,
  useRef,
  useCallback,
} from "react";

const useFetch = (promiseFn, args = [], options = {}) => {
  const initial_value = options.initial_value || options.default_value || {};

  const reducer = (state, action) => {
    const { type, data, error } = action;
    switch (type) {
      case "loading":
        return { ...state, loading: true, error: undefined };
      case "success":
        return { ...state, data, error: undefined };
      case "done":
        return { ...state, loading: false };
      case "error":
        return { data: options.default_value || {}, loading: false, error };
      default:
        throw new Error(`Unknown action type: ${type}`);
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    data: initial_value,
    loading: promiseFn ? true : false,
    error: undefined,
  });

  const { data, loading, error } = state;

  const fetch = useCallback(() => {
    let canceled = false;
    dispatch({ type: "loading" });
    try {
      const promise = promiseFn();
      if (promise && promise.then) {
        promise
          .then((data) => {
            if (!canceled) {
              dispatch({ type: "success", data });
              setTimeout(() => {
                if (!canceled) dispatch({ type: "done" });
              });
            }
          })
          .catch((error) => {
            if (!canceled) {
              dispatch({ type: "error", error });
              console.error(error);
            }
          });
      } else {
        console.error(
          `Function passed into useFetch should return a promise, received: ${promise}. To resolve, return Promise.resolve()`,
        );
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: "error", error: e || "Error while fetching" });
      canceled = true;
    }
    return () => (canceled = true);
  }, [promiseFn]);

  const fetchRef = useRef(fetch);

  useEffect(() => {
    fetchRef.current = fetch;
  }, [fetch]);

  const refresh = useCallback(() => {
    fetchRef.current();
  }, []);

  useLayoutEffect(fetch, args);

  return [data, loading, error, refresh];
};

export default useFetch;
