import React from "react";
import _ from "lodash";

import { Text } from "worklete/components";
import { useTranslation } from "worklete/hooks";
import colors from "config/theming/colors";
import "./TeamList.i18n";

import AdminBadge from "../OrgAdminBadge/OrgAdminBadge";
import { useWindowSize } from "worklete/hooks";

const TeamList = ({ classes, teams }) => {
  const { is_mobile } = useWindowSize();
  const { t } = useTranslation("TeamList");

  return (
    <React.Fragment>
      {_.map(teams, ({ id, name }) => {
        return (
          <div key={id} className={classes.teamWrapper}>
            <Text.Body className={classes.infoBodyText}>{name}</Text.Body>
            <AdminBadge
              style={{
                background: colors.blue_accent,
                color: "#fff",
                margin: is_mobile ? "2px 10px 10px 0" : "2px 90px 10px 0",
                verticalAlign: "middle",
                fontSize: "10px",
                opacity: 1,
              }}
            >
              {t("Team Admin")}
            </AdminBadge>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default TeamList;
