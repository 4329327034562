import { lazy } from "react";

const People = lazy(() => import("./People/People"));

const Training = lazy(() => import("./Training/Training"));

const CoursePlayer = lazy(() => import("./CoursePlayer/CoursePlayer"));

const Scoreboard = lazy(() => import("./Scoreboard/Scoreboard"));

const WorkleteAdmin = lazy(() => import("./WorkleteAdmin/WorkleteAdmin"));

const DebugRoutes = lazy(() => import("./Debug/DebugRoutes"));

export {
  People,
  Training,
  CoursePlayer,
  Scoreboard,
  WorkleteAdmin,
  DebugRoutes,
};
