import getDeviceInfo from "./getDeviceInfo";
import React, { useContext, useState, useMemo, useCallback } from "react";

const intitial_device = getDeviceInfo();

const default_device = intitial_device;
const default_refresh = () => {};

const DeviceInfoContext = React.createContext([
  default_device,
  default_refresh,
]);
DeviceInfoContext.displayName = "DeviceInfoContext";

const DeviceInfoManager = ({ children }) => {
  const [device, setDevice] = useState(intitial_device);

  const refresh = useCallback(() => {
    setDevice(getDeviceInfo());
  }, [setDevice]);

  const value = useMemo(() => {
    return [device, refresh];
  }, [device, refresh]);

  return (
    <DeviceInfoContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </DeviceInfoContext.Provider>
  );
};

const useDeviceInfo = () => {
  return useContext(DeviceInfoContext);
};

const DeviceInfoConsumer = DeviceInfoContext.Consumer;

const withDevice = (Component) => {
  return (props) => {
    const [device] = useDeviceInfo();
    return <Component {...props} device={device} />;
  };
};

export { DeviceInfoManager, useDeviceInfo, DeviceInfoConsumer, withDevice };
