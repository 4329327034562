import { useEffect, useState } from "react";

const WaitToShow = ({ wait = 250, children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setShow(true), wait);
    return () => clearTimeout(id);
  }, []); // eslint-disable-line

  return (show && children) || null;
};

export default WaitToShow;
