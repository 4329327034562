import { useReducer, useMemo, useCallback } from "react";
import { useOnMount } from "worklete/hooks";
import * as authApi from "../authApi";
import { loadHeaders } from "../authStorage";
import useSaveSesion from "./useSessionSaving";

const default_state = {
  sending: false,
  headers: {},
  data: {},
  error: undefined,
};

const reducer = (state, action) => {
  const { type, headers, data, error } = action;

  const prev = { ...state, updated_at: Date.now() };

  switch (type) {
    case "refresh-start":
      return { ...prev, sending: true };
    case "refresh-success":
      return { ...prev, headers, data, sending: false, error: undefined };
    case "refresh-error":
      return { ...prev, ...default_state, error };

    case "signin-start":
      return { ...prev, sending: true };
    case "signin-success":
      return { ...prev, sending: false, headers, data, error: undefined };
    case "signin-error":
      return { ...prev, sending: false, header: {}, data: {}, error };

    case "signout-start":
      return { ...prev, sending: true };
    case "signout-success":
      return { ...prev, ...default_state };
    case "signout-error":
      return { ...prev, sending: false, header: {}, data: {}, error };

    case "signup-start":
      return { ...prev, sending: true };
    case "signup-success":
      return { ...prev, sending: false, headers, data, error: undefined };
    case "signup-error":
      return { ...prev, sending: false, header: {}, data: {}, error };

    case "error":
      return { ...prev, error };
    default:
      console.warn("unknown type: ", type);
      return { ...state };
  }
};

const initial_headers = loadHeaders();

const inititial_state = { ...default_state, headers: initial_headers || {} };

const useSessionManagement = () => {
  const [state, dispatch] = useReducer(reducer, inititial_state);

  const { sending, headers, data, error } = state;

  useSaveSesion(headers);

  const wrap = (name, sending, func) => (...args) => {
    if (!sending) {
      dispatch({ type: `${name}-start` });
      return func(...args)
        .then(({ headers, ...data } = {}) => {
          dispatch({
            type: `${name}-success`,
            headers: headers || {},
            data: data || {},
          });
          return data;
        })
        .catch((error) => {
          dispatch({ type: `${name}-error`, error });
          console.log(`${name} error: `, error);
          throw error;
        });
    } else {
      return Promise.reject({ message: "currently sending" });
    }
  };

  const refreshSession = useCallback(
    wrap("refresh", sending, () => authApi.refreshSession(headers)),
    [headers, sending],
  );

  useOnMount(() => refreshSession().catch(() => {}));

  const signIn = useCallback(
    wrap("signin", sending, (...args) => authApi.signIn(...args)),
    [sending],
  );

  const signOut = useCallback(
    wrap("signout", sending, () => authApi.signOut(headers)),
    [headers, sending],
  );

  const signUp = useCallback(
    (options) => {
      if (!sending) {
        const { username, password } = options;
        return authApi
          .signUp(options)
          .then(() => signIn({ username, password }));
      } else {
        return Promise.reject({ message: "currently sending" });
      }
    },
    [sending, signIn],
  );

  const signInWithMasqueradeToken = useCallback(
    wrap("signin", sending, (...args) =>
      authApi.signInWithMasqueradeToken(...args),
    ),
    [sending],
  );

  const wrappedAuthApi = useMemo(() => {
    return {
      refreshSession,
      signIn,
      signOut,
      signUp,
      signInWithMasqueradeToken,
    };
  }, [refreshSession, signIn, signOut, signUp, signInWithMasqueradeToken]);

  return [data, headers, sending, wrappedAuthApi, error];
};

export default useSessionManagement;
