import React, { createContext, useContext } from "react";

const CurrentOrgContext = createContext({});

const CurrentMembershipContext = createContext({});

const OrganizationApiContext = createContext({});

const ChangeOidContext = createContext(() => {});

const CurrentOrgProviders = ({
  organization,
  membership,
  organizationApi,
  setOid,
  children,
}) => {
  return (
    <CurrentOrgContext.Provider value={organization}>
      <CurrentMembershipContext.Provider value={membership}>
        <OrganizationApiContext.Provider value={organizationApi}>
          <ChangeOidContext.Provider value={setOid}>
            {children}
          </ChangeOidContext.Provider>
        </OrganizationApiContext.Provider>
      </CurrentMembershipContext.Provider>
    </CurrentOrgContext.Provider>
  );
};

const useCurrentOrg = () => useContext(CurrentOrgContext);

const useCurrentMembership = () => useContext(CurrentMembershipContext);

const useOrganizationApi = () => useContext(OrganizationApiContext);

const useChangeOrganization = () => useContext(ChangeOidContext);

const useHasOrgAdminOrHigher = () => {
  return useContext(CurrentMembershipContext).has_org_admin_or_higher;
};

export {
  CurrentOrgProviders,
  useCurrentOrg,
  useCurrentMembership,
  useHasOrgAdminOrHigher,
  useOrganizationApi,
  useChangeOrganization,
};
