import _ from "lodash";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { useWindowSize } from "worklete/hooks";
import { Text } from "worklete/components";

const styles = (theme) => ({
  bodyCell: {
    borderBottom: "none",
  },
  bodyText: {
    fontWeight: 600,
    fontSize: "14px",
  },
});

const BodyCell = ({ classes, bodyStyle, collapsible, content, paddedText }) => {
  const { is_mobile } = useWindowSize();
  const isText = _.isString(content);

  return (
    <TableCell
      className={classes.bodyCell}
      style={
        collapsible ? { display: is_mobile && "none", ...bodyStyle } : bodyStyle
      }
    >
      {isText ? (
        <Text.Body
          style={{ paddingLeft: paddedText && "25px" }}
          className={classes.bodyText}
        >
          {content}
        </Text.Body>
      ) : (
        content
      )}
    </TableCell>
  );
};

export default withStyles(styles)(BodyCell);
