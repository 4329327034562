import { useReducer, useCallback, useEffect, useRef } from "react";

const reducer = (state, action) => {
  const { type, response, error } = action;
  switch (type) {
    case "sending":
      return { ...state, sending: true, error: undefined };
    case "success":
      return { response, sending: false, error: undefined };
    case "error":
      return { response: undefined, sending: false, error };
    case "clear":
      return { response: undefined, sending: false, error: undefined };
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};

const initialState = {
  response: undefined,
  sending: false,
  error: undefined,
};

const useWrite = (promiseFn) => {
  const unmounted = useRef(false);

  useEffect(() => () => (unmounted.current = true), []);

  const [state, _dispatch] = useReducer(reducer, initialState);

  const dispatch = useCallback((...args) => {
    if (!unmounted.current) _dispatch(...args);
  }, []);

  const { sending, response, error } = state;

  const send = (...args) => {
    if (sending) {
      throw new Error({ message: "Already Sending" });
    } else {
      dispatch({ type: "sending" });
      try {
        const promise = promiseFn(...args);

        if (!(promise && promise.then)) {
          console.error(
            "useWrite function did not return a promise, received: ",
            promise,
          );
          return Promise.resolve(promise);
        } else {
          return promise
            .then((response) => {
              dispatch({ type: "success", response });
              return response;
            })
            .catch((error) => {
              dispatch({ type: "error", error });
              throw error;
            });
        }
      } catch (err) {
        dispatch({ type: "error", error: err });
        return Promise.reject(err);
      }
    }
  };

  const clear = () => {
    if (!sending) dispatch({ type: "clear" });
  };

  return [send, sending, response, error, clear];
};

export default useWrite;
