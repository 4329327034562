import React from "react";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  superHeader: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    fontWeight: "500",
    paddingBottom: "10px",
  },
});

const SuperHeaderText = ({ classes, className, children, style }) => (
  <Typography
    className={classNames(classes.superHeader, className)}
    style={style}
    varient="body2"
  >
    {children}
  </Typography>
);

export default withStyles(styles)(SuperHeaderText);
