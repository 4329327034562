import _ from "lodash";
import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import "./SectionTable.scss";

const TableContext = createContext({});
const useTable = () => useContext(TableContext);

const { any } = PropTypes;

const propTypes = {
  cellPadding: any,
  headerPadding: any,
  dividerHeight: any,
};

const defaultProps = {
  cellPadding: "8px 16px",
  dividerHeight: "8px",
};

const Divider = (props) => {
  const { dividerHeight } = useTable();
  return (
    <tr className="section-table--body-row">
      <td
        style={{ height: dividerHeight }}
        colSpan={100}
        className="section-table--item-divider"
      />
    </tr>
  );
};

const divide = (list) => {
  const count = _.size(list);
  const items = [];
  _.each(list, (item, index) => {
    items.push(item);
    if (index < count - 1) items.push(<Divider key={"divider" + index} />);
  });
  return items;
};

const wrapItem = (item = "", Class, index) => {
  if (item && (item.type === Class || item.type === Divider)) return item;
  return (
    <Class key={`section-table-${Class.type}-${item}-${index}`}>{item}</Class>
  );
};

const wrapList = (children, Class) => {
  return _.map(React.Children.toArray(children), (cell, index) => {
    return wrapItem(cell, Class, index);
  });
};

const Cell = ({ style, ...props }) => {
  const { cellPadding } = useTable();
  return (
    <td
      {...props}
      style={{ padding: cellPadding, ...style }}
      className="section-table--body-cell"
    />
  );
};

const Row = ({ children }) => (
  <tr className="section-table--body-row">{wrapList(children, Cell)}</tr>
);

const HeaderCell = ({ style, ...props }) => {
  const { headerPadding, cellPadding } = useTable();
  return (
    <th
      {...props}
      style={{ padding: headerPadding || cellPadding, ...style }}
      className="section-table--header-cell"
    />
  );
};

const HeaderRow = ({ children }) => (
  <thead className="section-table--header">
    <tr className="section-table--header-row">
      {wrapList(children, HeaderCell)}
    </tr>
  </thead>
);

const SectionTable = React.forwardRef(
  ({ columns, headerRow, children, type, ...rest }, ref) => {
    const { cellPadding, headerPadding, dividerHeight } = rest;

    const header = headerRow || (
      <HeaderRow>{wrapList(columns, HeaderCell)}</HeaderRow>
    );

    const value = useMemo(() => {
      return { cellPadding, headerPadding, dividerHeight };
    }, [cellPadding, headerPadding, dividerHeight]);

    const items = type === "divided" ? divide(children) : children;

    return (
      <TableContext.Provider value={value}>
        <div className="section-table--root" ref={ref}>
          <table className="section-table--table">
            {header}
            <tbody className="section-table--body">
              {wrapList(items, Row)}
            </tbody>
          </table>
        </div>
      </TableContext.Provider>
    );
  },
);

SectionTable.defaultProps = defaultProps;
SectionTable.propTypes = propTypes;

SectionTable.HeaderRow = HeaderRow;
SectionTable.HeaderCell = HeaderCell;
SectionTable.Cell = Cell;
SectionTable.Row = Row;

export { Row, Cell, HeaderCell };

export default SectionTable;
