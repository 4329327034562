import { useLayoutEffect } from "react";

const useBodyScrollLock = (enabled) => {
  useLayoutEffect(() => {
    if (enabled) {
      const { overflowY } = window.getComputedStyle(document.body) || {};
      document.body.style.overflowY = "hidden";

      return () => (document.body.style.overflowY = overflowY);
    }
  }, [enabled]);
};

export default useBodyScrollLock;
