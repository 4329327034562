import _ from "lodash";
import React from "react";
import {
  useCurrentOrg,
  useOrganizationsList,
  useChangeOrganization,
} from "worklete/hooks";
import OrgRoutes from "./OrgRoutes/OrgRoutes";
import { Placeholder } from "worklete/components";
import OrganizationPicker from "./OrganizationPicker";
import AppLayout from "../AppLayout/AppLayout";

const RequireOrganizationController = () => {
  const orgs = useOrganizationsList();
  const { id } = useCurrentOrg();
  const changeOrganization = useChangeOrganization();

  return id ? (
    <OrgRoutes organization_id={id} />
  ) : (
    <AppLayout hideNavigation>
      {_.isEmpty(orgs) ? (
        <Placeholder loading message="Loading Organization..." />
      ) : (
        <OrganizationPicker
          organizations={orgs}
          onOrganizationChange={changeOrganization}
        />
      )}
    </AppLayout>
  );
};

export default RequireOrganizationController;
