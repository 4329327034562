import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import AdminBadge from "./OrgAdminBadge/OrgAdminBadge";
import TeamList from "./TeamList/TeamList";
import LogoutButton from "../LogoutButton";
import { getDisplayName } from "config/language/language";
import { Text } from "worklete/components";
import { useUser, useCurrentMembership, useTranslation } from "worklete/hooks";
import "./ProfileCard.i18n";

const ProfileCardContent = ({ classes }) => {
  const { t } = useTranslation("ProfileCard");

  const {
    email,
    first_name,
    last_name,
    language_code,
    username,
    worklete_employee,
  } = useUser();

  const { org_admin, job_title, admin_teams } = useCurrentMembership();

  return (
    <div className={classes.bodyWrapper}>
      <Text.Header className={classes.tealHeaderText}>
        {t("Profile")}
      </Text.Header>
      <Text.Header className={classes.fullNameHeader}>
        {`${first_name} ${last_name}`}
      </Text.Header>
      {org_admin ? (
        <AdminBadge>{t("OrganizationAdmin")}</AdminBadge>
      ) : worklete_employee ? (
        <AdminBadge>
          <Link
            to="/worklete-admin"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            {t("WorkleteEmployee")}
          </Link>
        </AdminBadge>
      ) : null}
      <Text.Body className={classes.greyBodyText}>{t("Username")}</Text.Body>
      <Text.Body
        className={classNames(classes.infoBodyText, classes.sectionEnd)}
      >
        {username}
      </Text.Body>
      <Text.Body className={classes.greyBodyText}>{t("Email")}</Text.Body>
      <Text.Body
        className={classNames(classes.infoBodyText, classes.sectionEnd)}
      >
        {email}
      </Text.Body>
      {!!job_title && (
        <React.Fragment>
          <Text.Body className={classes.greyBodyText}>
            {t("JobTitle")}
          </Text.Body>
          <Text.Body
            className={classNames(classes.infoBodyText, classes.sectionEnd)}
          >
            {job_title}
          </Text.Body>
        </React.Fragment>
      )}
      <Text.Body className={classes.greyBodyText}>{t("Language")}</Text.Body>
      <Text.Body
        className={classNames(classes.infoBodyText, classes.sectionEnd)}
      >
        {getDisplayName(language_code)}
      </Text.Body>
      {_.size(admin_teams) > 0 && (
        <React.Fragment>
          <Text.Body className={classes.greyBodyText}>{t("Team")}</Text.Body>
          <TeamList classes={classes} teams={admin_teams}></TeamList>
        </React.Fragment>
      )}
      <Text.Body className={classNames(classes.lastSection)} />
      <LogoutButton />
      <Text.Body className={classes.termsParagraph}>
        {t("ByCreating")}
        <br />
        <Text.Link
          className={classes.termsLink}
          to="https://worklete.com/worklete-terms-of-use"
        >
          {t("TermsOfUse")}
        </Text.Link>
        <span className={classes.paddedAnd}>{t("And")}</span>
        <Text.Link
          className={classes.termsLink}
          to="https://worklete.com/privacy-policy"
        >
          {t("PrivacyPolicy")}
        </Text.Link>
        .
      </Text.Body>
    </div>
  );
};

export default ProfileCardContent;
