import React from "react";
import BugsnagManager from "./BugsnagManager/BugsnagManager";
import { DeviceInfoManager } from "./device/DeviceInfoManager";
import { ServerInfoManager } from "./server/ServerInfoManager";
import { ResponsiveManager } from "./responsive/ResponsiveManager";
import { BrowserRouter as Router } from "react-router-dom";
import ThemeManager from "../../config/theming/ThemeManager";
import SettingsManagement from "./settings/SettingsManagement";

const SystemManagement = ({ children }) => {
  return (
    <BugsnagManager>
      <ThemeManager>
        <SettingsManagement>
          <DeviceInfoManager>
            <ServerInfoManager>
              <ResponsiveManager>
                <Router>{children}</Router>
              </ResponsiveManager>
            </ServerInfoManager>
          </DeviceInfoManager>
        </SettingsManagement>
      </ThemeManager>
    </BugsnagManager>
  );
};

export default SystemManagement;
