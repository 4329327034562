import { addToWorkleteGlobal } from "config/worklete-global";

const zEWrapper = (...args) =>
  window.zE ? window.zE(...args) : console.log("zendesk not found");

const updateZendesk = (name, email) =>
  zEWrapper(() =>
    window.zE.identify({
      email: email || "",
      name: name || "",
    }),
  );

const clearZendesk = () => zEWrapper("webWidget", "reset");

addToWorkleteGlobal("zendesk", { updateZendesk, clearZendesk });

export { updateZendesk, clearZendesk };
