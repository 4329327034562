import React, { forwardRef } from "react";
import { Link as RouterLink, NavLink as RouterNavLink } from "react-router-dom";
import "./Link.css";

const Link = forwardRef((props, ref) => {
  const { className, ...rest } = props;

  const classes = "link " + (className || "");

  return <RouterLink ref={ref} className={classes} {...rest} />;
});

const NavLink = forwardRef((props, ref) => {
  const { className, ...rest } = props;

  const classes = "nav-link " + (className || "");

  return (
    <RouterNavLink
      ref={ref}
      className={classes}
      activeClassName="is-active"
      {...rest}
    />
  );
});

export { NavLink };

export default Link;
