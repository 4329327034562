import addTranslations from "worklete/translations";

addTranslations("ResetPassword", {
  ResetHeader: {
    en: "Reset your password",
  },
  ResetSuperHeader: {
    en: "Welcome back!",
  },
  UpdatedHeader: {
    en: "Your password has been updated.",
  },
  UpdatedSuperHeader: {
    en: "Success! You're back in.",
  },
  UpdateEmailFormAuthButtonLabel: {
    en: "Continue",
  },
  UpdateEmailFormAuthEmailLabel: {
    en: "Email",
  },
  UpdateEmailFormEmailError: {
    en: "Error submitting email",
  },
  UpdateEmailFormEmailLabel: {
    en: "Make account recovery easier, update your email:",
  },
  UpdateEmailFormUsernameLabel: {
    en: "For reference, your Worklete username is:",
  },
  UpdatePasswordFormConfirmationPasswordLabel: {
    en: "Confirm Password",
  },
  UpdatePasswordFormContinueText: {
    en: "Continue",
  },
  UpdatePasswordFormDoesNotMatch: {
    en: "Does not match the above password",
  },
  UpdatePasswordFormPasswordLabel: {
    en: "Password",
  },
  UpdatePasswordFormPasswordTextLabel: {
    en: "Please enter your new password below:",
  },
  UpdatePasswordFormSubmissionError: {
    en: "Error submitting password change",
  },
});
