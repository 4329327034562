import React from "react";
import PropTypes from "prop-types";
import Placeholder from "worklete/components/Placeholder/Placeholder";
import { errorMessage } from "worklete/format/errors/errors";
import "./ErrorBoundary.scss";

const { func, any } = PropTypes;

class ErrorBoundary extends React.Component {
  static propTypes = {
    onError: func,
    fallback: any,
  };

  static defaultProps = {
    onError: () => {},
  };

  state = {
    error: undefined,
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
    this.props.onError(error, info);
  }

  render() {
    if (this.state.hasError) {
      const message = errorMessage(this.state.error) || "Unexpected Error";
      return (
        <div className="error-boundary--message">
          <Placeholder
            message={message}
            actionLabel="Reload the Page"
            onAction={() => window.location.reload()}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
