import { withStyles } from "@material-ui/styles";
import ProfileCardContent from "./ProfileCardContent";

const mobileStyles = (theme) => ({
  tealHeaderText: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    paddingBottom: "24px",
  },
  fullNameHeader: {
    fontSize: "22px",
    paddingBottom: "8px",
    display: "inline-block",
    marginRight: "5px",
  },
  greyBodyText: {
    color: theme.palette.text.dark,
    fontSize: "12px",
  },
  sectionEnd: {
    paddingBottom: "20px",
  },
  lastSection: {
    paddingBottom: "50px",
  },
  infoBodyText: {
    fontSize: "14px",
  },
  bodyWrapper: {
    paddingLeft: "20px",
  },
  termsParagraph: {
    paddingTop: "40px",
    textAlign: "left",
    fontSize: "12px",
  },
  termsLink: {
    fontSize: "12px",
  },
  paddedAnd: {
    padding: "0px 5px",
  },
  teamWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const MobileProfileContent = withStyles(mobileStyles)(ProfileCardContent);

export default MobileProfileContent;
