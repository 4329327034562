import React from "react";
import { Text } from "worklete/components";

import { useWindowSize } from "worklete/hooks";

const AuthHeader = ({ superHeader, header }) => {
  const { is_mobile } = useWindowSize();

  return (
    <React.Fragment>
      <Text.SuperHeader>{superHeader}</Text.SuperHeader>
      <Text.Header
        style={{
          paddingBottom: "25px",
          fontSize: is_mobile && "36px",
          maxWidth: is_mobile ? "350px" : "500px",
        }}
      >
        {header}
      </Text.Header>
    </React.Fragment>
  );
};

export default AuthHeader;
