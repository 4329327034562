import React, { createContext, useContext } from "react";

const noop = () => Promise.resolve({});

const SessionContext = createContext({ signed_in: false });

const UserContext = createContext({});

const AuthApiContext = createContext({
  refreshSession: noop,
  signIn: noop,
  signOut: noop,
  signUp: noop,
  signInWithMasqueradeToken: noop,
});

const SessionProviders = ({ session, user, authApi, children }) => {
  return (
    <SessionContext.Provider value={session}>
      <UserContext.Provider value={user}>
        <AuthApiContext.Provider value={authApi}>
          {children}
        </AuthApiContext.Provider>
      </UserContext.Provider>
    </SessionContext.Provider>
  );
};

const useSession = () => useContext(SessionContext);

const useUser = () => useContext(UserContext);

const useAuthApi = () => useContext(AuthApiContext);

export { SessionProviders, useSession, useUser, useAuthApi };
