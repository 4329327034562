import React from "react";
import NavigationTab from "./NavigationTab";
import { WorkleteLogo } from "worklete/components";
import {
  useWindowSize,
  useCurrentMembership,
  useTranslation,
} from "worklete/hooks";
import classNames from "classnames";
import "./AppNavigation.scss";

const AppHeader = () => {
  const { is_mobile } = useWindowSize();
  const { has_people_tab, has_scoreboard_tab } = useCurrentMembership();
  const { t } = useTranslation();

  return (
    <div
      className="app-navigation"
      style={{ padding: is_mobile ? "40px 16px" : "40px 50px" }}
    >
      <WorkleteLogo
        className={classNames("app-navigation--logo", {
          "is-compact": is_mobile,
        })}
        width={48}
      />

      {has_people_tab && (
        <NavigationTab compact={is_mobile} to="/people">
          {t("People")}
        </NavigationTab>
      )}

      <NavigationTab compact={is_mobile} to="/training">
        {t("Training")}
      </NavigationTab>

      {has_scoreboard_tab && (
        <NavigationTab compact={is_mobile} to="/scoreboard">
          {t("Scoreboard")}
        </NavigationTab>
      )}
    </div>
  );
};

export default AppHeader;
