import _ from "lodash";
import { addToWorkleteGlobal } from "config/worklete-global";
import { useMemo, useCallback, useEffect } from "react";
import {
  initial_settings,
  fetchSettings,
  saveSetting,
} from "./settingsHelpers";
import { useFetch } from "worklete/hooks";

const exists = (val) => typeof val !== "undefined" && val !== null;

const useSettingsManagement = (settings_config = {}) => {
  const options = { initial_value: initial_settings };
  const [vals, loading, error, refresh] = useFetch(fetchSettings, [], options);
  const local_values = vals || {};

  const settings = useMemo(() => {
    return _.mapValues(settings_config, (setting_data, key) => {
      const local_value = local_values[key];

      const value = exists(local_value)
        ? local_value
        : setting_data.default_value;

      return { ...setting_data, key, local_value, value };
    });
  }, [local_values, settings_config]);

  const setSetting = useCallback(
    (key, value) => {
      return saveSetting(key, value)
        .then(refresh)
        .catch(refresh);
    },
    [refresh],
  );

  useEffect(() => {
    addToWorkleteGlobal("setSetting", setSetting);
  }, [setSetting]);

  return {
    settings,
    loading,
    error,
    refresh,
    setSetting,
  };
};

export default useSettingsManagement;
