import { useEffect } from "react";

const updateUser = (user = {}, organization = {}) => {
  try {
    const { id: user_id, worklete_employee } = user;

    const { id: organization_id } = organization;

    window.gtag("set", {
      user_id,
      worklete_employee,
      organization_id,
    });
  } catch (e) {}
};

const useGoogleAnalytics = (user = {}, organization) => {
  const { id: user_id } = user;
  const { id: organization_id } = organization;

  const handleChange = () => {
    updateUser(user, organization);
    return () => updateUser({}, {});
  };

  useEffect(handleChange, [user_id, organization_id]);
};

export default useGoogleAnalytics;
