import React, { useState } from "react";
import { useTranslation } from "worklete/hooks";
import AuthLayout from "../AuthLayout";
import AuthHeader from "../AuthHeader";
import UpdatePasswordForm from "./UpdatePasswordForm";
import UpdateEmailForm from "./UpdateEmailForm";
import "./ResetPassword.i18n";

const ResetPasswordController = ({
  user_id,
  username,
  email,
  onFlowComplete,
  initialStep,
}) => {
  const [workflowStep, setWorkflowStep] = useState(initialStep || "Password");
  const { t } = useTranslation("ResetPassword");

  switch (workflowStep) {
    case "Password":
      return (
        <AuthLayout>
          <AuthHeader
            header={t("ResetHeader")}
            superHeader={t("ResetSuperHeader")}
          />
          <UpdatePasswordForm onComplete={() => setWorkflowStep("Email")} />
        </AuthLayout>
      );
    case "Email":
      return (
        <AuthLayout>
          <AuthHeader
            header={t("UpdatedHeader")}
            superHeader={t("UpdatedSuperHeader")}
          />
          <UpdateEmailForm
            username={username}
            email={email}
            user_id={user_id}
            onComplete={onFlowComplete}
          />
        </AuthLayout>
      );
    default:
      return <span />;
  }
};

export default ResetPasswordController;
