import React from "react";
import _ from "lodash";

import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

import BodyCell from "./BodyCell";

import colors from "config/theming/colors";

const SearchTableBody = ({ data, columns }) => (
  <TableBody>
    {data.map((item, index) => (
      <TableRow
        key={index}
        style={{
          backgroundColor:
            index % 2 === 0 ? colors.blue_highlight : colors.portland_rain,
        }}
      >
        {columns.map((column, index) => {
          const bodyProps = _.pick(column, ["collapsible", "bodyStyle"]);

          return (
            <BodyCell
              {...bodyProps}
              content={column.genContent(item)}
              paddedText={index === 0}
              key={index}
            />
          );
        })}
      </TableRow>
    ))}
  </TableBody>
);

export default SearchTableBody;
