import { useState, useEffect } from "react";

import * as LDClient from "launchdarkly-js-client-sdk";

import { addToWorkleteGlobal } from "config/worklete-global";

const LAUNCH_DARKLY_ENV_KEY = process.env.REACT_APP_LAUNCH_DARKLY_ENV_KEY;

const default_user = { key: "unknown" };

const ldclient = LDClient.initialize(LAUNCH_DARKLY_ENV_KEY, default_user);

addToWorkleteGlobal("ldclient", ldclient);

const getLaunchDarklyUser = (user, team, organization) => {
  const { id: user_id, worklete_employee, name, email } = user || {};

  const { id: team_id, name: team_name } = team || {};

  const { id: organization_id, name: organization_name } = organization || {};

  const [key, type] = worklete_employee
    ? [`user_${user_id}`, "User"]
    : team_id
    ? [`team_${team_id}`, "Team"]
    : organization_id
    ? [`org_${organization_id}`, "Organization"]
    : ["unknown", "Unknown User"];

  return {
    key,
    name,
    email,
    privateAttributeNames: ["email"],
    custom: {
      groups: [],
      type,
      team_id,
      team_name,
      organization_id,
      organization_name,
      worklete_employee,
    },
  };
};

const useLaunchDarklyManagement = (user, team, organization) => {
  const [initializing, setInitializing] = useState(false);
  const [flags, setFlags] = useState(ldclient.allFlags());

  useEffect(() => {
    ldclient.on("ready", () => setInitializing(false));

    const onChange = () => setFlags(ldclient.allFlags());

    ldclient.on("change", onChange);

    return () => ldclient.off("change", onChange);
  }, []);

  useEffect(() => {
    const launch_darkly_user = getLaunchDarklyUser(user, team, organization);

    ldclient.identify(launch_darkly_user, null, () => {});
  }, [user, team, organization]);

  return { initializing, ldclient, flags };
};

const useFlag = (key, fallback) => {
  const getVal = () => ldclient.variation(key, fallback);

  const [value, setValue] = useState(getVal);

  useEffect(() => {
    const onChange = (next, prev) => setValue(next);
    ldclient.on(`change:${key}`, onChange);
    return () => ldclient.off(`change:${key}`, onChange);
  }, [key]);

  return value;
};

export { useFlag, useLaunchDarklyManagement };
