import addTranslations from "worklete/translations";

addTranslations("SignIn", {
  AuthHeaderSignIn: {
    en: "Please log in to your Worklete account",
  },
  AuthLabelPassword: {
    en: "Password",
  },
  AuthLabelUsername: {
    en: "Username",
  },
  AuthSignIn: {
    en: "Sign In",
  },
  AuthSuperHeaderSignIn: {
    en: "Welcome back!",
  },
  CantAccessMyAccount: {
    en: "I can't access my account",
  },
  ErrorPassword: {
    en: "Please enter your password",
  },
  ErrorUsername: {
    en: "Please enter your username",
  },
  HaveInviteCode: {
    en: "Have an invite code?",
  },
});
