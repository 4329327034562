import MobileDetect from "mobile-detect";

const getDeviceInfo = () => {
  const md = new MobileDetect(window.navigator.userAgent);

  const info = {
    IS_IPHONE: !!md.is("iPhone"),
    IS_IPAD: md.is("iPad"),
    ANDROID_VERSION: md.version("Android") || null,
    GRADE: md.mobileGrade(),
    OS: md.os(),
    PHONE: md.phone(),
    USER_AGENT: md.userAgent(),
  };

  return info;
};

export default getDeviceInfo;
