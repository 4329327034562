import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import SectionTable from "../SectionTable/SectionTable";
import Text from "../Text/Text";
import { useLayoutProperties } from "worklete/hooks";

const { arrayOf, func, object, string } = PropTypes;

const propTypes = {
  items: arrayOf(object).isRequired,
  renderItemContent: func.isRequired,
  itemAction: func.isRequired,
  itemActionLabel: string.isRequired,
  checkDisabled: func,
  disabledLabel: string,
};

const { Cell, Row } = SectionTable;

const ActionTable = ({
  items,
  renderItemContent,
  itemAction,
  itemActionLabel,
  checkDisabled,
  disabledLabel,
}) => {
  const { is_mobile } = useLayoutProperties();

  const fontSize = is_mobile ? 14 : 16;

  const rows = _.map(items, (item, index) => (
    <Row key={`key_${index}`}>
      <Cell>{renderItemContent(item)}</Cell>
      <Cell>
        {!!checkDisabled && checkDisabled(item) ? (
          <Text.Body style={{ fontSize, fontFamily: "Gibson SemiBold" }}>
            {disabledLabel}
          </Text.Body>
        ) : (
          <Text.Link style={{ fontSize }} onClick={() => itemAction(item)}>
            {itemActionLabel}
          </Text.Link>
        )}
      </Cell>
    </Row>
  ));

  return (
    <SectionTable
      cellPadding={is_mobile ? "8px" : "20px"}
      type="divided"
      dividerHeight={16}
    >
      {rows}
    </SectionTable>
  );
};

ActionTable.propTypes = propTypes;

export default ActionTable;
