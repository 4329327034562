import _ from "lodash";

const errorMessage = (error, default_message = "Unknown Error") => {
  const err = (error && error.error) || error;
  try {
    if (!err) return undefined;
    if (_.isString(err)) return err;
    if (_.isString(err.error)) return err.error;
    if (_.isString(err.message)) return err.message;
    if (_.isString(err.errors[0])) return err.errors[0];
    if (_.isString(err.full_messages[0])) return err.full_messages[0];
    return default_message;
  } catch (e) {
    return default_message;
  }
};

export { errorMessage };
