import _ from "lodash";
import React from "react";
import { withStyles } from "@material-ui/styles";

import { StepButtonGroup } from "worklete/components";
import { languages } from "config/language/language";
import AuthButton from "../../AuthButton";

const styles = (theme) => ({
  languageButton: {
    margin: "16px 0px",
  },
});

const SelectLanguage = ({ classes, onSelect }) => {
  return (
    <StepButtonGroup>
      {_.map(languages, ({ code, displayName }) => {
        return (
          <AuthButton
            key={code}
            className={classes.languageButton}
            onTimeout={() => onSelect(code)}
            text={displayName}
          />
        );
      })}
    </StepButtonGroup>
  );
};

export default withStyles(styles)(SelectLanguage);
